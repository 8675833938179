import { dashboardData } from "assets/data/data";
import React from "react";

import { Link } from "react-router-dom";
// import CommercialForms from "views/admin/CommercialForms";

// components

export default function DashboardCards() {
  return (
    <>
      <div className="flex flex-wrap  max-w-md gap-10 row-gap-8 lg:max-w-screen-lg sm:row-gap-10 lg:grid-cols-3 xl:max-w-screen-lg sm:mx-auto">
        {dashboardData.map((data) => (
          <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
            <div className="flex flex-col transition duration-300 bg-white rounded shadow-sm hover:shadow">
              <div className="relative w-full h-76">
                <img
                  src={data.img}
                  className="object-cover w-full h-full rounded-t"
                  alt="Plan"
                />
              </div>
              <div className="flex flex-col justify-between flex-grow p-8 border border-t-0 rounded-b">
                <div>
                  <div className="text-lg font-semibold">{data.title}</div>
                  <p className="text-sm text-gray-900">{data.subTitle}</p>
                </div>
                <Link to={data.link}>
                  <button
                    className="bg-blueGray-800 mt-4 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                    type="button"
                  >
                    {data.buttom}
                  </button>
                </Link>

                {/* <a
                href="#"
                className="inline-flex bg-blue-600 items-center justify-center w-full h-12 px-6 font-medium tracking-wide text-white transition duration-200 rounded shadow-md bg-deep-purple-accent-400 hover:bg-deep-purple-accent-700 focus:shadow-outline focus:outline-none"
              >
                Book Basic
              </a> */}
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  );
}
