import OxygenPlant from "assets/img/Oxygen_plant_picture.jpg";
import carriar from "assets/img/gallery3.jfif";
import commercial from "assets/img/carter_domestic.png";
import mainImg from "assets/img/courier_man.jpg";
import commercials from "../../assets/img/commercial.jpeg";
import medical from "../../assets/img/carter_domestic.png";

export const dashboardData = [
  {
    img: commercials,
    title: "Commercial Logistics",
    subTitle:
      "Superior service is only a click away! Choose us for an unforgettable customer experience.",
    buttom: "Book For Pick up",
    link: "/admin/commercialforms",
  },
  {
    img: medical,
    title: "Medical Logistics",
    subTitle:
      "A life to save, a click away! Consistent in the safe delivery of medical products.",
    buttom: "Book For Pick up",
    link: "/admin/cardmedical",
  },
];
export const indexStart = [
  {
    headerText: "CARTER BiGGS LOGISTICS COMPANY",
    subText:
      "Carter Biggs Logistics Company is a trail blazer in an industry where there is a fine line between success and failure. The Company realized that the medical supply chain is its own unique structure that holds different requirements, standards, and demands. In addition, our professionals are trained to tailor work approaches to the unique needs of our client, enabling us to offer to our clients, tailor-made solutions and services that suit their logistic requirements. Our combination of international and local market knowledge and perspective gives us an edge in the medical logistics space in Nigeria.",
    fButton: "Get started",
    sButton: "Contact Us",
    imgLink: mainImg,
  },
];

export const polygonCards = [
  {
    alt: "...",
    src: commercial,
    title: "Commercial Logistics",
    discription: " Request delivery for food, pasel etc...",
  },
  {
    alt: "...",
    src: OxygenPlant,
    title: "OXYGEN SALES AND DELIVERY",
    discription:
      "  Carter Biggs has since December 2020 spearheaded the swift delivery of pressure-filled oxygen gas cylinders.",
  },
  {
    alt: "...",
    src: carriar,
    title: "COVID-19 CAREPACKS DELIVERY",
    discription:
      "With the outbreak of Covid-19 came a very tough time for the citizens of Lagos state.",
  },
];

export const faqs = {
  title: " Frequently Asked Questions (FAQ)",
  sections: [
    {
      number: "0",
      questions: " How long would it take for me to get my blood request?",
      answers:
        "Requests are attended to in a timely and efficient manner and can be tracked on the web application",
    },
    {
      number: "1",
      questions: "How do i track the rider?",
      answers:
        "We have a tracker on the bookings section of the web application",
    },
    {
      number: "2",
      questions: "Do you work 24hours?",
      answers: "Yes ,we do ,our services are 24 hours",
    },
    {
      number: "3",
      questions: "How am I sure the blood is safe?",
      answers:
        " We guarantee the delivery of safe blood by only partnering with accredited blood banks that comply with WHO standards in the collection and storage of blood.",
    },
    {
      number: "4",
      questions: "Can I return blood If I don't use it?",
      answers:
        "If it exceeds 12 hours after delivery, the possibility of a return will depend exclusively on the policy of the blood bank that supplied the blood.",
    },
    {
      number: "5",
      questions: "What is the purity of your oxygen?",
      answers:
        "We maintain a purity level of 93%-99% for medical oxygen at our plants",
    },
    {
      number: "6",
      questions: "In what sizes do you deliver cylinders?",
      answers:
        "We currently deliver oxygen in 2㎥, 6㎥ , and 7㎥ cylinders. Special delivery options are available on- demand.",
    },
    {
      number: "7",
      questions: "What’s the pressure of your gas?",
      answers: "150 bar",
    },
    {
      number: "8",
      questions: "Do you sell cylinders and accessories?",
      answers: "Yes. These are available upon request by customers.",
    },
  ],
};

// export const
