/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

import IndexNavbar from "components/Navbars/IndexNavbar.js";
import Footer from "components/Footers/Footer.js";
import Faq from "components/Cards/FAQ";

import Select from "react-select";
import ViewOrderDetails from "components/Modals/viewOrderDetails";
import { polygonCards } from "assets/data/data";
import { indexStart } from "assets/data/data";

export default function Index() {
  const allData = [
    { far: "car", time: "car", id: "0" },
    { far: "cat", time: "cat", id: "1" },
    { far: "dog", time: "dog", id: "2", id: "3" },
    { far: "something", time: "something", id: "4" },
  ];
  // const getOptions = () => {
  const options = allData.map((d) => ({ value: d.far, label: d.time }));
  // };
  return (
    <>
      <IndexNavbar fixed />

      <section className="header relative pt-16 items-center flex h-screen max-h-860-px">
        {indexStart.map((data) => (
          <>
            <div className="container mx-auto items-center flex flex-wrap">
              <div className="w-full md:w-8/12 lg:w-6/12 xl:w-6/12 px-4">
                <div className="pt-32 sm:pt-0">
                  <h2 className="font-semibold text-4xl text-blueGray-600">
                    {data.headerText}
                  </h2>
                  <p className="mt-4 text-lg leading-relaxed text-blueGray-500">
                    {data.subText}
                  </p>
                  <div className="mt-12">
                    <Link
                      className="get-started text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-lightBlue-500 active:bg-lightBlue-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      to="/auth/login"
                    >
                      {data.fButton}
                    </Link>
                    <a
                      href="https://carterbiggs.com.ng/contact-us.html"
                      className="github-star ml-1 text-white font-bold px-6 py-4 rounded outline-none focus:outline-none mr-1 mb-1 bg-blueGray-700 active:bg-blueGray-600 uppercase text-sm shadow hover:shadow-lg ease-linear transition-all duration-150"
                      target="_blank"
                    >
                      {data.sButton}
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <img
              className="absolute top-0 b-auto right-0 pt-16 sm:w-6/12 -mt-48 sm:mt-0 w-10/12 max-h-860px"
              src={data.imgLink}
              alt="..."
            />
          </>
        ))}
      </section>

      <section className="mt-48 md:mt-40 pb-40 relative bg-blueGray-100">
        <div
          className="-mt-20 top-0 bottom-auto left-0 right-0 w-full absolute h-20"
          style={{ transform: "translateZ(0)" }}
        >
          <svg
            className="absolute bottom-0 overflow-hidden"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon
              className="text-blueGray-100 fill-current"
              points="2560 0 2560 100 0 100"
            ></polygon>
          </svg>
        </div>

        <div className="container mx-auto">
          <div className="flex flex-wrap items-center">
            {/* start of three cards */}
            {polygonCards.map((cards) => (
              <div className=" md:w-6/12 lg:w-4/12 px-12 sm:mt-10 md:px-4 md:py-4 mr-auto ml-auto lg:-mt-32 md:mt-40 -mt-14 ">
                <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                  <img
                    alt={cards.alt}
                    src={cards.src}
                    className="w-full align-middle rounded-t-lg"
                  />
                  <blockquote className="relative p-8 mb-4">
                    <svg
                      preserveAspectRatio="none"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 583 95"
                      className="absolute left-0 w-full block h-95-px -top-94-px"
                    >
                      <polygon
                        points="-30,95 583,95 583,65"
                        className="text-lightBlue-500 fill-current"
                      ></polygon>
                    </svg>
                    <h4 className="text-xl font-bold text-white">
                      {cards.title}
                    </h4>
                    <p className="text-md font-light mt-2 text-white">
                      {cards.discription}
                    </p>
                  </blockquote>
                </div>
              </div>
            ))}
            {/* <div className="md:w-6/12 lg:w-4/12 px-12 md:px-4 md:py-4 mr-auto ml-auto lg:-mt-32 md:-mt-32 -mt-14 ">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src={OxygenPlant}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    OXYGEN SALES AND DELIVERY
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    Carter Biggs has since December 2020 spearheaded the swift
                    delivery of pressure-filled oxygen gas cylinders.
                  </p>
                </blockquote>
              </div>
            </div>
            <div className="md:w-6/12 lg:w-4/12 px-12 md:px-4 md:py-4 mr-auto ml-auto lg:-mt-32 md:-mt-32 -mt-14 ">
              <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded-lg bg-lightBlue-500">
                <img
                  alt="..."
                  src={carriar}
                  className="w-full align-middle rounded-t-lg"
                />
                <blockquote className="relative p-8 mb-4">
                  <svg
                    preserveAspectRatio="none"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 583 95"
                    className="absolute left-0 w-full block h-95-px -top-94-px"
                  >
                    <polygon
                      points="-30,95 583,95 583,65"
                      className="text-lightBlue-500 fill-current"
                    ></polygon>
                  </svg>
                  <h4 className="text-xl font-bold text-white">
                    COVID-19 CAREPACKS DELIVERY
                  </h4>
                  <p className="text-md font-light mt-2 text-white">
                    With the outbreak of Covid-19 came a very tough time for the
                    citizens of Lagos state.
                  </p>
                </blockquote>
              </div>
            </div> */}
            {/* end of three cards */}
          </div>
        </div>
      </section>
      <section>
        <Faq />
      </section>

      <Footer />
    </>
  );
}
