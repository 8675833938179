import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { Toaster } from "react-hot-toast";

import "@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/styles/tailwind.css";

// layouts

import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";

// views without layouts

import Landing from "views/Landing.js";
import Index from "views/Index.js";
import { AuthProvider } from "./context/AuthProvider";
// import { AuthProvider } from "./hooks/useAuths";
import ProtectedRoute from "hooks/ProtectedRoutes";
// import useAuths from "hooks/useAuths";
import { useAuthContext } from "./context/AuthProvider";
import Loader from "components/Loader/Loader";

function App() {
  const { loaded, isAuth } = useAuthContext();

  // const [isAuth, isSuccess, login, logout] = useAuths();

  // useEffect(() => {
  //   setIsAuth(true);
  //   setIsSuccess(true);
  //   // console.log(isAuth + "a  k" + isSuccess);
  // }, [isAuth, isSuccess]);

  return (
    <Switch>
      {!loaded ? (
        <Loader />
      ) : (
        <ProtectedRoute path="/admin" component={Admin} isAuth={isAuth} />
      )}
      {/* add routes with layouts */}

      {/* <Route component={RequireAuth}> */}
      {/* <Route path="/admin" component={<RequireAuth>Admin</RequireAuth>} /> */}
      {/* <Route path="admin" element={<Admin />} /> */}
      {!loaded ? <Loader /> : <Route path="/auth/" component={Auth} />}
      {!loaded ? <Loader /> : <Route path="/" exact component={Index} />}
      {/* </Route> */}
      {/* add routes without layouts */}
      <Route path="/landing" exact component={Landing} />
      {/* add redirect for first page */}
      <Redirect from="*" to="/" />
    </Switch>
  );
}
export default App;

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <AuthProvider>
        <Toaster />
        <Route path="/*" component={App} />
      </AuthProvider>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
