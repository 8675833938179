import React from "react";

export const ModalStack = ({ title, content }) => {
  return (
    <div className="w-full lg:w-3/12 md:w-3/12 sm:6/12 px-4">
      <div className="relative w-full mb-3">
        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
          {title}
        </label>
        {content ? content : "Not Available"}
      </div>
    </div>
  );
};
