import { useAuthContext } from "context/AuthProvider";
import React from "react";

// components

export default function CardProfilesDetails() {
  const { userData } = useAuthContext();

  return (
    <>
      <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-xl rounded-lg mt-16">
        <div className="px-6">
          <div className="flex flex-wrap justify-center">
            <div className="w-full px-4 flex justify-center">
              <div className="relative">
                <img
                  alt="..."
                  src={
                    require("assets/img/default-user-profile-picture.png")
                      .default
                  }
                  className="shadow-xl rounded-full h-auto align-middle border-none absolute -m-16 -ml-20 lg:-ml-16 max-w-150-px"
                />
              </div>
            </div>

            <div className="flex flex-wrap mt-20">
              <div className="w-full lg:w-5/12 sm:w-4/12  px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm text-blueGray-400 uppercase">
                    Fullname :
                  </span>
                </div>
              </div>
              <div className="w-full lg:w-7/12 sm:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm font-bold block uppercase tracking-wide text-blueGray-600">
                    {userData.data.fullname === null ||
                    userData.data.fullname === ""
                      ? "Update Personal Detail"
                      : userData.data.fullname}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="w-full lg:w-5/12 sm:w-4/12  px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm text-blueGray-400 uppercase">
                    Email Address :
                  </span>
                </div>
              </div>
              <div className="w-full lg:w-7/12 sm:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm font-bold block uppercase tracking-wide text-blueGray-600">
                    {userData.data.email === null || userData.data.email === ""
                      ? "Update Contact Detail"
                      : userData.data.email}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap">
              <div className="w-full  lg:w-5/12 sm:w-4/12  px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm text-blueGray-400 uppercase">
                    Mobile Number:
                  </span>
                </div>
              </div>
              <div className="w-full lg:w-7/12 sm:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm font-bold block uppercase tracking-wide text-blueGray-600">
                    {userData.data.telephone_no === null ||
                    userData.data.telephone_no === ""
                      ? "Update Contact Detail"
                      : userData.mobile_number || userData.data.telephone_no}
                  </span>
                </div>
              </div>
            </div>
            <div className="flex flex-wrap ">
              <div className="w-full lg:w-5/12 sm:w-4/12  px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm text-blueGray-400 uppercase">
                    <i className="fas fa-map-marker-alt mr-2 text-lg text-blueGray-400"></i>{" "}
                    Resident Address :
                  </span>
                </div>
              </div>
              <div className="w-full lg:w-7/12 sm:w-8/12 px-4">
                <div className="relative w-full mb-3">
                  <span className="text-sm font-bold block uppercase tracking-wide text-blueGray-600">
                    {userData.data.home_address === null ||
                    userData.data.home_address === ""
                      ? "Update Home Address"
                      : userData.data.home_address || userData.home_address}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
