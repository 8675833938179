import React, { useRef, useState } from "react";
import { Link, Redirect } from "react-router-dom";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useAuthContext } from "context/AuthProvider";
// import _ from "lodash";
import { useForm } from "react-hook-form";
import { TailSpin } from "react-loader-spinner";

// credentials
// email:adminas@gmail.com
// password:qazwsxedc

export default function Login() {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
  });
  const {
    isAuth,
    setIsAuth,
    userData,
    setUserData,
    setSuccess,
    setMedItemType,
    setCommercialItem,
    setPriority,
    setMedItem,
    setRequestTableData,
    REQUEST_URL,
  } = useAuthContext();

  const errRef = useRef();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  // const { isSubmitting } = submitting;
  // const { isSubmitting } = errors;
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "REQUEST_SIGNIN",
          api_key: process.env.REACT_APP_RQUEST_API,
          device_id: "01010101",
          user: "web",
          email_address: data.email,
          password: data.password,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (
        response.data === null ||
        response.data === "" ||
        response.data.data === "" ||
        response.data.resp_msg === "User login failed"
      ) {
        setErrMsg("User Not Found!");
        setSuccess(false);
      } else {
        const medItemType = await axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "GET_MEDICAL_ITEM_TYPES",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        const commercialItem = await axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "GET_COMMERCIAL_ITEM_TYPES",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );

        const medItem = await axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "GET_MEDICAL_ITEMS",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        const priority = await axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "GET_PRIORITIES",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        const tabledata = await axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "GET_USER_BOOKINGS",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
            user_id: response.data.data.user_id,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        setErrMsg("User Signed In");
        setUserData(response.data);
        setMedItemType(medItemType.data);
        setCommercialItem(commercialItem.data);
        setMedItem(medItem.data);
        setPriority(priority.data);
        setRequestTableData(tabledata.data.rides);

        setIsAuth(true);

        toast.success("Welcome To Your Dashboard");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err?.response?.status === 500) {
        setErrMsg("Internal Server Error");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err?.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      {isAuth ? (
        <>
          {userData.data.change_password_requested === "1" ? (
            <Redirect to="/auth/changepassword" />
          ) : (
            <Redirect to="/admin" />
          )}
        </>
      ) : (
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-xs uppercase font-bold">
                      Welcome to Carter Biggs Logistics platform
                    </h6>
                    {/* error message */}

                    <div
                      className={
                        errMsg
                          ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                          : null
                      }
                    >
                      <p
                        ref={errRef}
                        className={"text-white my-2 "}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                    </div>
                    {/* error message */}
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign In
                    </h6>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email
                      </label>
                      <input
                        {...register("email", {
                          required: "Please enter email address.",
                          // pattern: !/\S+@\S+\.\S+/,
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        id="email"
                        name="email"
                        type="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email"
                        // ref={emailRef}
                        // value={values.email}
                        onChange={(e) => setEmail(e.target.value.toLowerCase())}
                        // required
                      />
                      <div className="text-red-500">
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="password"
                      >
                        Password
                      </label>
                      <input
                        {...register("password", {
                          required: "Please enter your password.",
                          // pattern: {
                          //   value:
                          //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          //   message: "Invalid password",
                          // },
                          // maxLength: 12,
                          minLength: 8,
                        })}
                        id="=password"
                        name="password"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Password"
                        // value={values.password}
                        onChange={(e) =>
                          setPassword(e.target.value.toLowerCase())
                        }
                        // required
                      />
                      <div className="text-red-500">
                        {errors.password && (
                          <small className="text-danger">
                            {errors.password.message}
                          </small>
                        )}

                        {errors.password &&
                          errors.password.type === "minLength" && (
                            <span>
                              Password must be atleast 8 characters long
                            </span>
                          )}
                      </div>
                    </div>
                    <div>
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          Remember me
                        </span>
                      </label>
                    </div>

                    {/* <Link to="/admin"> */}
                    <div className="text-center mt-6">
                      <button
                        disabled={isSubmitting}
                        className="bg-blueGray-800 disabled:bg-blueGray-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        // type="button"
                      >
                        {isSubmitting ? (
                          <div className="flex justify-evenly items-center font-black bg-transparent backdrop-blur-sm">
                            <TailSpin color="#FFFFFF" height={30} width={80} />
                            Submitting
                          </div>
                        ) : (
                          "Sign In"
                        )}
                      </button>
                    </div>
                    <div className="flex flex-wrap mt-6 relative">
                      <div className="w-1/2">
                        <a
                          href="#pablo"
                          onClick={(e) => e.preventDefault()}
                          className="ml-2 text-sm font-semibold text-blueGray-600"
                        >
                          <Link to="/auth/forgotten">
                            <small className="text-red-500">
                              Forgot password?
                            </small>
                          </Link>
                        </a>
                      </div>
                      <div className="w-1/2 text-right">
                        <Link
                          to="/auth/register"
                          className="ml-2 text-sm font-semibold text-blueGray-600"
                        >
                          <small>Create new account</small>
                        </Link>
                      </div>
                    </div>
                    {/* </Link> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
