import axios from "axios";
import IdleTimer from "components/Idleness/IdeleTime";
import _ from "lodash";
import { createContext, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useIdleTimer } from "react-idle-timer";
import { Redirect } from "react-router";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [isAuth, setIsAuth] = useState(false);
  const [userData, setUserData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [medItemType, setMedItemType] = useState(null);
  // const [facility, setFacility] = useState([]);
  const [medItem, setMedItem] = useState([]);
  // const [medItemDesc, setMedItemDesc] = useState([]);
  const [commercialItem, setCommercialItem] = useState([]);
  const [itemTypeSelected, setItemTypeSelected] = useState("");
  const [requestTableData, setRequestTableData] = useState(null);
  const [tableDataUpdated, setTableDataUpdated] = useState(false);
  const [priority, setPriority] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [timeLineModal, setTimeLineModal] = useState(false);
  const [confirmCancelModal, setConfirmCancelModal] = useState(false);
  const [confirmRequestModal, setConfirmRequestModal] = useState(false);
  const [modalData, setModalData] = useState({});
  const [paginatedData, setPaginatedData] = useState(null);
  const [pageSelected, setPageSelected] = useState(5);
  const [tableFilted, setTableFilted] = useState();
  // medicalTable is for displaying these new table
  const [medicalTable, setMedicalTable] = useState([]);
  const [finalmedicalTable, setFinalMedicalTable] = useState([]);
  const [values, setValues] = useState(null);

  const versionCheck = "v1.9";
  // const onIdle = () => {
  //   //isUserReauthenticated;
  //   // Close Modal Prompt
  //   // Do some idle action like log out your user
  //   localStorage.setItem("isIdle", "true");
  //   // setIsModal(true);
  // };
  // const { isIdle } = useIdleTimer({
  //   onIdle,
  //   timeout: 500,
  // });
  const [medicalInput, setMedicalInput] = useState({
    requestType: "",
    itemType: "",
    itemName: [{}],
    description: "",
    pickup: "",
    dropoff: "",
    qtyItem: "",
    Priority: "",
    fullname: "",
    email: "",
    phone: "",
  });
  // onchange={(e)=> setMedicalInput({...medicalInput,[e.target.name]:`${e.target.value}`})}

  const pageSizes = [
    { id: "0", label: "5", value: "5" },
    { id: "1", label: "10", value: "10" },
    { id: "2", label: "15", value: "15" },
    { id: "3", label: "20", value: "20" },
  ];
  const pageSize = pageSelected;
  // console.log("pageSelected", pageSelected);
  const REQUEST_URL = "/mobile-api/";

  const viewBooking = (booking) => {
    setModalData(booking);
  };
  // medical item options
  const medItemOptions = medItem?.map(
    ({ item, item_type, ins_item_code, price, units, dosage_form }) => ({
      label: item,
      value: item_type,
      ins_item_code: ins_item_code,
      price: price,
      units: units,
      dosage_form: dosage_form,
    })
  );
  // final medical item options
  // const finalmedicalTableDetails = medicalTable.map(
  //   ({
  //     item_name,
  //     order_item_name_list,
  //     order_item_quantity_list,
  //     order_item_units,
  //   }) => ({
  //     item_name: item_name,
  //     // order_item_name_list for itemcode
  //     order_item_name_list: order_item_name_list,
  //     order_item_quantity_list: order_item_quantity_list,
  //     order_item_units: order_item_units,
  //   })
  // );

  // this is processed to the database finally
  const medicDetailfinal = (selectedItemName, unit) => {
    const updatedProducts = finalmedicalTable.findIndex((finalmedicalTable) => {
      if (finalmedicalTable.id === selectedItemName.id) {
        return finalmedicalTable.id;
      } else {
        return null;
      }
    });
    let newArray = [...finalmedicalTable];
    newArray[updatedProducts].unit = unit;

    setFinalMedicalTable(newArray);
  };

  // these for managing medical table
  const medicalOrderTable = (searchItems) => {
    //let updateTable;

    //if (medicalTable.length > 0) {
    //  updateTable = medicalTable.find((item) => {
    //     if (searchItems === item.label) {
    //       return true;
    //     } else {
    //       return false;
    //     }
    //   });
    // } else {
    //   updateTable = false;
    // }
    // console.log("updateTable status", medicalTable);
    // console.log("searchItems inside", searchItems);
    // if (!updateTable) {
    let productFound;

    let foundProduct = medItemOptions.find((item) => {
      if (searchItems === item.label) {
        productFound = true;
        return item;
      } else {
        productFound = false;
        return null;
      }
    });
    // console.log("these product found", foundProduct);

    let updatedArray;

    let foundProductWithQuantity = {
      ...foundProduct,
      quantity: 1,
      id: Math.floor(Math.random() * 9999) + 1000,
    };
    // console.log("foundProductWithQuantity::", foundProductWithQuantity);
    if (productFound) {
      updatedArray = [...medicalTable, foundProductWithQuantity];
    } else if (!productFound) {
      updatedArray = [...medicalTable];
    }
    // console.log(
    //   "updatedArray",
    //   updatedArray.map((data) => data.value)
    // );
    // console.log(medicalTable.map((data) => data.value));

    // let updatedArrayCheck = updatedArray.map((data) => data.value);
    // let medicalTableCheck = medItemOptions.find((item) => {
    //   console.log("item.value::", item.value);
    //   console.log("updatedArrayCheck::", updatedArrayCheck);
    //   if (item.value.toString() !== updatedArrayCheck) {
    //     return alert("waw wow");
    //   } else {
    //     return alert("nothing wow");
    //   }
    // });
    // // map((data) => data.value);
    // console.log("medicalTableCheck", medicalTableCheck);
    // if (medicalTableCheck === "true") {
    //   // <Redirect to="/admin/" />;
    //   alert("it happened");
    // }
    setMedicalTable(updatedArray);
    setFinalMedicalTable(updatedArray);
    toast.success(`${foundProduct.label.toLocaleUpperCase()} added to list.`);

    // } else {
    // const updatedProducts = medicalTable.findIndex((medicalsTable) => {
    //   if (medicalsTable.label === searchItems) {
    //     return medicalsTable.ins_item_code;
    //   } else {
    //     return null;
    //   }
    // });
    // let newArray = [...medicalTable];
    // newArray[updatedProducts].quantity =
    //   newArray[updatedProducts].quantity + 1;
    // setMedicalTable(newArray);
    // setFinalMedicalTable(newArray);
    // toast.success(
    //   `${newArray[updatedProducts].quantity} ${newArray[
    //     updatedProducts
    //   ].label.toLocaleUpperCase()} added to list.`
    // );
    // }
    setValues(null);
  };

  // end these for managing medical table
  // start search fuctions
  const onSearch = (searchItems) => {
    if (searchItems !== null) {
      setValues(searchItems);
      medicalOrderTable(searchItems);
    } else {
      setValues(null);
    }
  };
  // end search fuctions
  // start removal function
  const onRemoveFromTable = (product) => {
    const updatedProducts = medicalTable.findIndex((medicalsTable) => {
      if (medicalsTable.id === product.id) {
        return medicalsTable.id;
      } else {
        return null;
      }
    });

    let newArray = [...medicalTable];

    const updatedProductSelected = medicalTable.filter(
      (item) => item.id !== product.id
    );

    newArray[updatedProducts].quantity = 1;

    newArray = updatedProductSelected;

    //console.log('Index of updated Drug is', updatedDrugs);

    setMedicalTable(newArray);
    setFinalMedicalTable(newArray);

    toast.error(`${product.label.toLocaleUpperCase()} removed from list.`);
  };
  // end removal function

  // start increment function
  const inc = (product) => {
    const updatedProducts = medicalTable.findIndex((medicalsTable) => {
      if (medicalsTable.id === product.id) {
        return medicalsTable.id;
      } else {
        return null;
      }
    });

    let newArray = [...medicalTable];

    newArray[updatedProducts].quantity = newArray[updatedProducts].quantity + 1;

    //console.log('Index of updated Drug is', updatedProducts);

    setMedicalTable(newArray);
    setFinalMedicalTable(newArray);
    // console.log(newArray);

    toast.success(`1 ${product.label.toLocaleUpperCase()} added.`);
  };

  const dec = (drug) => {
    const updatedProducts = medicalTable.findIndex((medicalTable) => {
      if (medicalTable.id === drug.id) {
        return medicalTable.id;
      } else {
        return null;
      }
    });

    let newArray = [...medicalTable];

    if (newArray[updatedProducts].quantity <= 1) {
      const updatedProductSelected = medicalTable.filter(
        (item) => item.id !== drug.id
      );

      newArray = updatedProductSelected;
    } else {
      newArray[updatedProducts].quantity =
        newArray[updatedProducts].quantity - 1;
    }

    //console.log('Index of updated Drug is', updatedProducts);

    setMedicalTable(newArray);
    setFinalMedicalTable(newArray);

    toast.error(`1 ${drug.label.toLocaleUpperCase()} removed.`);
  };
  useEffect(() => {
    const timer = new IdleTimer({
      timeout: 1000, //expire after 1000 seconds
      onTimeout: () => {
        localStorage.clear();
        setIsAuth(false);
        <Redirect to="/auth/login" replace />;
        // console.log("user has been idle for:", "5 minute");
      },
      onExpired: () => {
        // do something if expired on load
        localStorage.clear();
        setIsAuth(false);
        <Redirect to="/auth/login" replace />;
        // console.log("user has expired for:", "5 minute");
      },
    });
    return () => {
      timer.cleanUp();
    };
  }, []);

  // useEffect(() => {
  //   if (localStorage.getItem("isIdle") === "true") {
  //     handleLogout();
  //   }
  // }, []);
  // console.log("medicalTable", medicalTable);
  useEffect(() => {
    if (requestTableData !== null) {
      setPaginatedData(_(requestTableData).slice(0).take(pageSize).value());
    }
  }, [requestTableData]);

  useEffect(() => {
    const data = window.localStorage.getItem("succeed");
    if (data !== null) setSuccess(JSON.parse(data));
  }, []);
  useEffect(() => {
    window.localStorage.setItem("succeed", JSON.stringify(success));
  }, [success]);

  // useEffect for storing authenticated status in local
  useEffect(() => {
    const data = window.localStorage.getItem("authenticated");
    if (data !== null) setIsAuth(JSON.parse(data));
    setLoaded(true);
  }, []);
  useEffect(() => {
    window.localStorage.setItem("authenticated", JSON.stringify(isAuth));
  }, [isAuth]);

  // useEffect for storing userData in local
  useEffect(() => {
    const userData = window.localStorage.getItem("userData");
    if (userData !== null) setUserData(JSON.parse(userData));
  }, []);
  useEffect(() => {
    if (userData !== null || userData !== "") {
      window.localStorage.setItem("userData", JSON.stringify(userData));
    }
  }, [userData]);
  // useEffect for storing medItemType in local
  useEffect(() => {
    const medItemType = window.localStorage.getItem("medItemType");
    if (medItemType !== null) setMedItemType(JSON.parse(medItemType));
  }, []);
  useEffect(() => {
    if (medItemType !== null || medItemType !== "") {
      window.localStorage.setItem("medItemType", JSON.stringify(medItemType));
    }
  }, [medItemType]);

  // useEffect for storing facilities in local
  // useEffect(() => {
  //   const facility = window.localStorage.getItem("facility");
  //   if (facility !== null) setFacility(JSON.parse(facility));
  // }, []);
  // useEffect(() => {
  //   if (facility !== null || facility !== "") {
  //     window.localStorage.setItem("facility", JSON.stringify(facility));
  //   }
  // }, [facility]);

  // useEffect for storing commercialItem in local
  useEffect(() => {
    const commercialItem = window.localStorage.getItem("commercialItem");
    if (commercialItem !== null) setCommercialItem(JSON.parse(commercialItem));
  }, []);
  useEffect(() => {
    if (commercialItem !== null || commercialItem !== "") {
      window.localStorage.setItem(
        "commercialItem",
        JSON.stringify(commercialItem)
      );
    }
  }, [commercialItem]);

  // useEffect for storing medItemDesc in local
  // useEffect(() => {
  //   const medItemDesc = window.localStorage.getItem("medItemDesc");
  //   if (medItemDesc !== null) setMedItemDesc(JSON.parse(medItemDesc));
  // }, []);
  // useEffect(() => {
  //   if (medItemDesc !== null || medItemDesc !== "") {
  //     window.localStorage.setItem("medItemDesc", JSON.stringify(medItemDesc));
  //   }
  // }, [medItemDesc]);

  // useEffect for storing medItem in local
  useEffect(() => {
    const medItem = window.localStorage.getItem("medItem");
    if (medItem !== null) setMedItem(JSON.parse(medItem));
  }, []);
  useEffect(() => {
    if (medItem !== null || medItem !== "") {
      window.localStorage.setItem("medItem", JSON.stringify(medItem));
    }
  }, [medItem]);

  // useEffect for storing priority in local
  useEffect(() => {
    const priority = window.localStorage.getItem("priority");
    if (priority !== null) setPriority(JSON.parse(priority));
  }, []);
  useEffect(() => {
    if (priority !== null || priority !== "") {
      window.localStorage.setItem("priority", JSON.stringify(priority));
    }
  }, [priority]);

  // useEffect for storing priority in local
  useEffect(() => {
    const requestTableData = window.localStorage.getItem("tabledata");
    //console.log("This is what local storage has on refresh", typeof(requestTableData));

    if (requestTableData !== null)
      setRequestTableData(JSON.parse(requestTableData));
  }, []);
  useEffect(() => {
    if (requestTableData !== null || requestTableData !== "") {
      window.localStorage.setItem(
        "tabledata",
        JSON.stringify(requestTableData)
      );
    }
  }, [requestTableData]);

  // useEffect for requesting and storing Requests in local
  async function updateTabledata() {
    setLoaded(false);
    const userID = userData?.data?.user_id;
    // console.log(userID);
    const response = await axios.post(
      REQUEST_URL,
      JSON.stringify({
        method: "GET_USER_BOOKINGS",
        api_key: process.env.REACT_APP_RQUEST_API,
        user: "web",
        user_id: `${userID}`,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    const data = await response.data;
    // console.log("Promise Data is", data);
    // console.log("Rides data: ", data.rides);

    if (data.rides !== null) {
      setRequestTableData(data.rides);
      window.localStorage.setItem("tabledata", JSON.stringify(data.rides));
    }
    setLoaded(true);
    setTableDataUpdated(false);
  }

  useEffect(() => {
    if (isAuth && tableDataUpdated) {
      updateTabledata();
    } else {
      return;
    }
    //setRequestTableData(response.data);
    //console.log("data containing the table", response);
    //console.log("requestTableData", requestTableData);
    // const priority = window.localStorage.getItem("priority");
    // if (priority !== null) setPriority(JSON.parse(priority));
  }, [tableDataUpdated]);

  return (
    <AuthContext.Provider
      value={{
        onSearch,
        onRemoveFromTable,
        inc,
        dec,
        medItemOptions,
        versionCheck,
        isAuth,
        setIsAuth,
        userData,
        setUserData,
        success,
        setSuccess,
        medItemType,
        viewBooking,
        modalData,
        setMedItemType,
        loaded,
        medItem,
        setMedItem,
        commercialItem,
        setCommercialItem,
        timeLineModal,
        setTimeLineModal,
        medicalInput,
        setMedicalInput,
        confirmCancelModal,
        setConfirmCancelModal,
        confirmRequestModal,
        setConfirmRequestModal,
        requestTableData,
        setRequestTableData,
        tableDataUpdated,
        setTableDataUpdated,
        itemTypeSelected,
        setItemTypeSelected,
        finalmedicalTable,
        setFinalMedicalTable,
        showModal,
        setShowModal,
        priority,
        setPriority,
        paginatedData,
        setPaginatedData,
        pageSize,
        //updateTabledata,
        pageSelected,
        setPageSelected,
        pageSizes,
        tableFilted,
        setTableFilted,
        medicalTable,
        setMedicalTable,
        medicDetailfinal,
        REQUEST_URL,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
