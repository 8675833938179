import { faqs } from "assets/data/data";
import React, { useState } from "react";
function Faq() {
  const [question, setquestion] = useState(0);
  console.log(faqs.title);
  console.log(faqs.sections.map((data) => data.number));

  return (
    <div className="pt-16">
      <div className="container mx-auto pt-16 ">
        <div className="text-center pb-3 md:pb-10 xl:pb-20">
          <h1 className="px-2 xl:px-0 xl:text-4xl md:text-4xl text-4xl font-semibold text-gray-800">
            <div className="text-blueGray-500 p-3 text-center inline-flex items-center justify-center w-16 h-16 mb-6 shadow-lg rounded-full bg-white">
              <i className="fas fa-file-alt text-xl"></i>
            </div>{" "}
            {faqs.title}
          </h1>
        </div>
        <div className="w-10/12 mx-auto">
          <ul>
            {faqs.sections.map((data) => (
              <li className="py-6 border-gray-200 border-solid border-b">
                <div className="flex justify-between items-center">
                  <h3 className="text-gray-800 text-base md:text-md  xl:text-3xl w-10/12">
                    {data.questions}
                  </h3>
                  <div
                    className="cursor-pointer"
                    onClick={() => (
                      console.log(data.number),
                      question === data.number
                        ? setquestion(null)
                        : setquestion(data.number)
                    )}
                  >
                    {question === data.number ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={36}
                        height={36}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#A0AEC0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        aria-label="Close"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx={12} cy={12} r={9} />
                        <line x1={9} y1={12} x2={15} y2={12} />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={36}
                        height={36}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="#A0AEC0"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        aria-label="Open"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" />
                        <circle cx={12} cy={12} r={9} />
                        <line x1={9} y1={12} x2={15} y2={12} />
                        <line x1={12} y1={9} x2={12} y2={15} />
                      </svg>
                    )}
                  </div>
                </div>
                {question === data.number && (
                  <p className="pt-2 md:pt-3  lg:pt-5 text-gray-800 bg-blueGray-100 text-sm md:text-base  xl:text-sm rounded-b-lg">
                    {data.answers}
                  </p>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Faq;
