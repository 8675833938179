import axios from "axios";
import React, { useRef, useState } from "react";
import { toast } from "react-hot-toast";
import { Redirect } from "react-router";
import { useForm } from "react-hook-form";
import { useAuthContext } from "context/AuthProvider";
import { TailSpin } from "react-loader-spinner";
import { Link } from "react-router-dom";

export default function Register() {
  const errRef = useRef();

  const [healthFacility, setHealthFacility] = useState("");
  const [partner, setPartner] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  // const [phone, setPhone] = useState("");
  // const [password, setPassword] = useState("");

  const { REQUEST_URL } = useAuthContext();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    watch,
  } = useForm({
    mode: "all",
  });
  const onSubmit = async (data) => {
    try {
      const response = await axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "REQUEST_SIGNUP",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",
          device_id: "01010101",
          facility_name: data.facility,
          is_partner: partner,
          surname: data.surname,
          othernames: data.othernames,
          mobile_number: data.telephone,
          email: data.email,
          password: data.password,
        }),
        {
          headers: {
            "Access-Control-Allow-Origin": "*",
            Accept: "application/json",
            "Content-Type": "application/json",
          },

          withCredentials: true,
        }
      );

      if (response?.data.resp_code === "002") {
        setErrMsg("User already exist");
        setSuccess(false);
      } else {
        toast.success("User has been created");
        setSuccess(true);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };
  // console.log(partner);

  return (
    <>
      {success ? (
        <Redirect to="/auth/login" />
      ) : (
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-6/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <h6 className="text-blueGray-500 text-xs uppercase font-bold">
                      Welcome to Carter Biggs Logistics platform
                    </h6>
                    {/* error message */}
                    <div
                      className={
                        errMsg
                          ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                          : null
                      }
                    >
                      <p
                        ref={errRef}
                        className={"text-white my-2 "}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                    </div>
                    {/* error message */}
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Sign Up
                    </h6>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>

                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form action onSubmit={handleSubmit(onSubmit)}>
                    <div className="flex flex-wrap">
                      {" "}
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <h6 className="text-blueGray-500 w-full uppercase text-xs font-bold">
                            Are you a health facility?
                          </h6>

                          <div className="flex w-6/12 justify-between ">
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="radio"
                                value="Yes"
                                name="facility"
                                onClick={(e) =>
                                  setHealthFacility(e.target.value)
                                }
                                className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                              />{" "}
                              <span className=" uppercase text-blueGray-600 text-xs font-semibold ">
                                Yes
                              </span>
                            </label>
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="radio"
                                value="No"
                                name="facility"
                                onClick={(e) =>
                                  setHealthFacility(e.target.value)
                                }
                                className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                              />{" "}
                              <span className=" uppercase text-blueGray-600 text-xs font-semibold ">
                                No
                              </span>{" "}
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <h6 className="text-blueGray-500 w-full uppercase text-xs font-bold">
                            Are you in partnership With Carter BiGGS?
                          </h6>

                          <div className="flex w-6/12 justify-between ">
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="radio"
                                value="Yes"
                                name="partners"
                                onClick={(e) => setPartner(e.target.value)}
                                className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                              />{" "}
                              <span className=" uppercase text-blueGray-600 text-xs font-semibold ">
                                Yes
                              </span>{" "}
                            </label>
                            <label className="inline-flex items-center cursor-pointer">
                              <input
                                type="radio"
                                value="No"
                                name="partners"
                                onClick={(e) => setPartner(e.target.value)}
                                className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                              />{" "}
                              <span className=" uppercase text-blueGray-600 text-xs font-semibold ">
                                No
                              </span>
                            </label>
                          </div>
                        </div>
                      </div>
                      {healthFacility === "Yes" && (
                        <div className="w-full lg:w-12/12 px-4">
                          <div className="relative w-full mb-3">
                            <label
                              className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                              htmlFor="facility"
                            >
                              Facility Name
                            </label>
                            <input
                              {...register("facility", {
                                required: "Please enter your facility name.",
                              })}
                              id="facility"
                              name="facility"
                              type="text"
                              className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                              placeholder="Enter Facility Name"
                            />
                            <div className="text-red-500">
                              {errors.facility && (
                                <small className="text-danger">
                                  {errors.facility.message}
                                </small>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="surname"
                          >
                            Surname
                          </label>
                          <input
                            {...register("surname", {
                              required: "Please enter your surname.",
                            })}
                            id="surname"
                            name="surname"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Surname"
                          />
                          <div className="text-red-500">
                            {errors.surname && (
                              <small className="text-danger">
                                {errors.surname.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="othernames"
                          >
                            Other Names
                          </label>
                          <input
                            {...register("othernames", {
                              required: "Please enter other names.",
                            })}
                            id="othernames"
                            name="othernames"
                            type="text"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Other Names"
                          />
                          <div className="text-red-500">
                            {errors.othernames && (
                              <small className="text-danger">
                                {errors.othernames.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="email"
                          >
                            Email Address
                          </label>
                          <input
                            {...register("email", {
                              required: "Please enter email address.",
                              // pattern: !/\S+@\S+\.\S+/,
                              pattern: {
                                value:
                                  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                message: "Invalid email address",
                              },
                            })}
                            id="email"
                            name="email"
                            type="email"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Email Address"
                          />
                          <div className="text-red-500">
                            {errors.email && (
                              <small className="text-danger">
                                {errors.email.message}
                              </small>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="telephone"
                          >
                            Mobile No.
                          </label>
                          <input
                            {...register("telephone", {
                              required: "Please enter your phone number.",
                              pattern: {
                                value:
                                  /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                                message: "Invalid phone number",
                              },
                              maxLength: 12,
                              minLength: 10,
                            })}
                            id="telephone"
                            name="telephone"
                            type="text"
                            inputMode="numeric"
                            pattern="[0-9]*"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Enter Mobile Number"
                            // value={values.telephone}
                            // onChange={(e) =>
                            //   setPhone(e.target.value.toLowerCase())
                            // }
                          />
                          <div className="text-red-500">
                            {errors.telephone && (
                              <small className="text-danger">
                                {errors.telephone.message}
                              </small>
                            )}

                            {errors.telephone &&
                              errors.telephone.type === "maxLength" && (
                                <span>Max length exceeded</span>
                              )}
                            {errors.telephone &&
                              errors.telephone.type === "minLength" && (
                                <span>Number must be 10 digits long</span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-wrap">
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="password"
                          >
                            Password
                          </label>
                          <input
                            {...register("password", {
                              required: "Please enter your password.",
                              // pattern: {
                              //   value:
                              //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                              //   message: "Invalid password",
                              // },
                              // maxLength: 12,
                              minLength: 8,
                            })}
                            id="password"
                            name="password"
                            type="password"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Password"
                            // onChange={(e) =>
                            //   setPassword(e.target.value.toLowerCase())
                            // }
                          />
                          <div className="text-red-500">
                            {errors.password && (
                              <small className="text-danger">
                                {errors.password.message}
                              </small>
                            )}

                            {/* {errors.password && errors.password.type === "maxLength" && (
                      <span>Max length exceeded</span>
                    )} */}
                            {errors.password &&
                              errors.password.type === "minLength" && (
                                <span>
                                  Password must be atleast 8 characters long
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                      <div className="w-full lg:w-6/12 px-4">
                        <div className="relative w-full mb-3">
                          <label
                            className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                            htmlFor="cpassword"
                          >
                            Confirm Password
                          </label>
                          <input
                            {...register("cpassword", {
                              required: "Please enter your password.",

                              validate: (val) => {
                                if (watch("password") !== val) {
                                  return "Your passwords do no match";
                                }
                              },
                              minLength: 8,
                            })}
                            id="cpassword"
                            name="cpassword"
                            type="password"
                            className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            placeholder="Confirm Password"
                          />
                          <div className="text-red-500">
                            {errors.cpassword && (
                              <small className="text-danger">
                                {errors.cpassword.message}
                              </small>
                            )}

                            {/* {errors.cpassword && errors.cpassword.type === "maxLength" && (
                      <span>Max length exceeded</span>
                    )} */}
                            {errors.cpassword &&
                              errors.cpassword.type === "minLength" && (
                                <span>
                                  Confirm Password must be atleast 8 characters
                                  long
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-between">
                      <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox border-0 rounded text-blueGray-700 ml-1 w-5 h-5 ease-linear transition-all duration-150"
                        />
                        <span className="ml-2 text-sm font-semibold text-blueGray-600">
                          I agree with the
                          <a
                            href="#pablo"
                            className="text-lightBlue-500"
                            onClick={(e) => e.preventDefault()}
                          >
                            {" "}
                            Privacy Policy
                          </a>
                        </span>
                      </label>
                      <div className="justify-end text-right">
                        <Link
                          to="/auth/login"
                          className="ml-2 text-sm font-semibold text-blueGray-600"
                        >
                          <small> Already have Accoount?</small>
                          <small className="text-red-500"> SignIn</small>
                        </Link>
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        // disabled={
                        //   !values.email ||
                        //   !values.password ||
                        //   !values.cpassword ||
                        //   !values.surname ||
                        //   !values.othernames ||
                        //   !values.telephone
                        //     ? true
                        //     : false
                        // }
                        disabled={isSubmitting}
                        className="bg-blueGray-800 disabled:bg-blueGray-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                        type="submit"
                      >
                        {isSubmitting ? (
                          <div className="flex justify-evenly items-center font-black bg-transparent backdrop-blur-sm">
                            <TailSpin color="#FFFFFF" height={30} width={80} />
                            Submitting
                          </div>
                        ) : (
                          "Create Account"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
