import React, { useRef, useState } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { useForm } from "react-hook-form";
import { useAuthContext } from "context/AuthProvider";
import { TailSpin } from "react-loader-spinner";

export default function Forgotten() {
  const [isSubmitted, setIsSubmitted] = useState(false);
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");
  // const [success, setSuccess] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    // reset,
    // watch,
    // control,
  } = useForm({
    mode: "all",
  });
  const { REQUEST_URL } = useAuthContext();
  const onSubmit = async (data) => {
    try {
      await axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "REQUEST_FORGOTTEN_PASSWORD",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",

          email_address: data.email,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      setIsSubmitted(true);
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 409) {
        setErrMsg("Username Taken");
      } else {
        setErrMsg("Registration Failed");
      }
      errRef.current.focus();
    }
  };
  return (
    <>
      {isSubmitted ? (
        <>
          <Redirect to="/auth/login" replace />
        </>
      ) : (
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    {/* error message */}

                    <div
                      className={
                        errMsg
                          ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                          : null
                      }
                    >
                      <p
                        ref={errRef}
                        className={"text-white my-2 "}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                    </div>
                    {/* error message */}
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Forgotten Password
                    </h6>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="email"
                      >
                        Email Address
                      </label>
                      <input
                        {...register("email", {
                          required: "Please enter email address.",
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Invalid email address",
                          },
                        })}
                        type="email"
                        id="email"
                        name="email"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Email Address"
                      />
                      <div className="text-red-500">
                        {errors.email && (
                          <small className="text-danger">
                            {errors.email.message}
                          </small>
                        )}
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        disabled={isSubmitting}
                        className="bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      >
                        {isSubmitting ? (
                          <div className="flex justify-evenly items-center font-black bg-transparent backdrop-blur-sm">
                            <TailSpin color="#FFFFFF" height={30} width={80} />
                            Submitting
                          </div>
                        ) : (
                          "Submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
