import axios from "axios";
import { useAuthContext } from "context/AuthProvider";
import React, { useState } from "react";
import { toast } from "react-hot-toast";
import { Redirect } from "react-router";

export default function ConfirmRequest() {
  const { modalData, setConfirmRequestModal, REQUEST_URL } = useAuthContext();
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const handleSubmits = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "CONFIRM_DELIVERY",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",
          request_id: modalData.order_request_id,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );

      if (response?.data === null || response?.data === "") {
        setErrMsg("User Not Found");
        setSuccess(false);
      } else {
        toast.success("Order has been confirmed");
        setSuccess(true);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err?.response?.status === 500) {
        setErrMsg("Internal Server Error");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err?.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else if (err?.response?.status === null) {
        setErrMsg("User Not Found");
      } else {
        setErrMsg("Request Failed");
      }
    }
  };
  return (
    <>
      {success ? (
        <Redirect to="admin/tables" />
      ) : (
        <>
          <div className="justify-center items-center bg-black-fade flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-2/5 md:4/5 sm:4/5 h-6/12  my-6 mx-auto">
              <div className="relative px-4 w-full h-full  md:flex md:items-center md:justify-center">
                <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                  <div className="md:flex items-center">
                    <div className="rounded-full border bg-emerald-500   border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="48"
                        height="48"
                        fill="currentColor"
                        class="bi bi-check-circle"
                        viewBox="0 0 16 16"
                        id="IconChangeColor"
                      >
                        {" "}
                        <path
                          d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
                          id="mainIconPathAttribute"
                          fill="#ffffff"
                        ></path>{" "}
                        <path
                          d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z"
                          id="mainIconPathAttribute"
                          fill="#ffffff"
                        ></path>{" "}
                      </svg>
                    </div>
                    <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                      <p className="font-bold">Delete your account</p>
                      <div className="inline-block">
                        <p className="text-sm text-gray-700 mt-1">
                          You will lose all of your data by canceling these
                          order.This action cannot be undone.
                        </p>
                        <p className="text-sm text-gray-700 mt-1">
                          Are you sure you want to proceed.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button
                      className="block  uppercase w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-emerald-500 text-emerald-800 rounded-lg font-semibold text-sm md:ml-2 md:order-2"
                      onClick={handleSubmits}
                    >
                      Confirm Order
                    </button>

                    <button
                      className="block w-full md:inline-block md:w-auto text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setConfirmRequestModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" blur"></div>
        </>
      )}
    </>
  );
}
