import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

// components
import { useAuthContext } from "context/AuthProvider";
import axios from "axios";
import _ from "lodash";
import ReactPaginate from "react-paginate";
import Select from "react-select";
import Pagination from "components/Table/Pagination";
import { Controller } from "react-hook-form";

export default function CardTable({ color }) {
  const {
    setPageSelected,
    requestTableData,
    setShowModal,
    setTimeLineModal,
    viewBooking,
    pageSize,
    pageSizes,
    setTableFilted,
    tableFilted,
  } = useAuthContext();
  const [currentPage, setCurrentPage] = useState(1);
  const filtedTableList = [
    { id: "0", label: "Pending", value: "pending" },
    { id: "1", label: "Delivered", value: "delivered" },
    { id: "2", label: "Declined", value: "declined" },
    { id: "3", label: "Paid", value: "paid" },
  ];
  // console.log("requestTableData: ", requestTableData);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    return requestTableData !== null
      ? requestTableData?.slice(firstPageIndex, lastPageIndex)
      : "";
  }, [currentPage, pageSize, requestTableData]);
  // console.log("currentTableData real deal: ", currentTableData);

  return (
    <>
      <div
        className={
          "relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded " +
          (color === "light" ? "bg-white" : "bg-lightBlue-900 text-white")
        }
      >
        <div className="rounded-t mb-0 px-4 py-3 border-0">
          <div className="flex flex-wrap items-center">
            <div className="relative w-full px-4 max-w-full flex-grow flex-1">
              <h3
                className={
                  "font-semibold text-lg " +
                  (color === "light" ? "text-blueGray-700" : "text-white")
                }
              >
                My Bookings
              </h3>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap mt-4">
          <div className="w-full lg:w-2/12 px-4">
            <div className="relative w-full mb-3">
              <div className="flex items-center">
                <label className="block uppercase text-blueGray-600 text-xs font-bold mb-0">
                  Pages:
                </label>
                <select
                  className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                  onChange={(e) => setPageSelected(e.target.value)}
                >
                  {pageSizes.map((range) => (
                    <option key={range.id} value={range.value}>
                      {range.label}{" "}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          {/* <div className="w-full justify-between lg:w-6/12 px-4">
            <div className="relative w-full mb-3">
              <Select
                options={filtedTableList}
                // inputRef={ref}
                placeholder={"Filter Status"}
                value={filtedTableList.value}
                onChange={(val) => {
                  setTableFilted(val.value.toLowerCase());
                }}
                // value={filtedTableList.find(
                //   (c) => c.value === value
                // )}
                // onChange={(val) => {
                //   onChange(val.value);
                //   setItemTypeSelected(val.value.toLowerCase());
                // }}
                // />
              />
            </div>
          </div> */}
        </div>
        <div className="flex"></div>

        <div className="block w-full overflow-x-auto">
          <table className="items-center w-full bg-transparent border-collapse">
            <thead>
              <tr>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Request Type
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Booking ID
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Status
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Item Name
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Posted Time
                </th>
                <th
                  className={
                    "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                    (color === "light"
                      ? "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                      : "bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700")
                  }
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {currentTableData !== "" ? (
                currentTableData?.map((ride, index) => (
                  <tr key={index}>
                    <th className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 ">
                      {ride.request_type}
                    </th>

                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {ride.order_request_id}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      {ride.order_status === "declined" ? (
                        <i className="fas fa-circle text-red-500 mr-2"></i>
                      ) : ride.order_status === "pending" ? (
                        <i className="fas fa-circle text-orange-500 mr-2"></i>
                      ) : ride.order_status === "paid" ? (
                        <i className="fas fa-circle text-gray-500 mr-2"></i>
                      ) : ride.order_status === "completed" ||
                        ride.order_status === "delivered" ? (
                        <i className="fas fa-circle text-emerald-500 mr-2"></i>
                      ) : null}
                      {/* <i className="fas fa-circle text-emerald-500 mr-2"></i>{" "} */}
                      {ride.order_status}
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex">{ride.item_name}</div>
                    </td>
                    <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                      <div className="flex items-center">
                        <span className="mr-2">{ride.datetime_added}</span>
                      </div>
                    </td>
                    <td className="border-t-0 px-6 mx-6 align-middle flex flex-wrap border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right">
                      <div className="w-full lg:w-4/12 pl-2 ">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-eye"
                          viewBox="0 0 16 16"
                          id="IconChangeColor"
                          onClick={() => {
                            setShowModal(true);
                            viewBooking(ride);
                          }}
                        >
                          {" "}
                          <path
                            d="M16 8s-3-5.5-8-5.5S0 8 0 8s3 5.5 8 5.5S16 8 16 8zM1.173 8a13.133 13.133 0 0 1 1.66-2.043C4.12 4.668 5.88 3.5 8 3.5c2.12 0 3.879 1.168 5.168 2.457A13.133 13.133 0 0 1 14.828 8c-.058.087-.122.183-.195.288-.335.48-.83 1.12-1.465 1.755C11.879 11.332 10.119 12.5 8 12.5c-2.12 0-3.879-1.168-5.168-2.457A13.134 13.134 0 0 1 1.172 8z"
                            id="mainIconPathAttribute"
                          ></path>{" "}
                          <path
                            d="M8 5.5a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5zM4.5 8a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0z"
                            id="mainIconPathAttribute"
                          ></path>{" "}
                        </svg>
                      </div>
                      <div className=" w-full lg:w-4/12 pl-3">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          className="bi bi-activity"
                          viewBox="0 0 16 16"
                          id="IconChangeColor"
                          onClick={() => {
                            setTimeLineModal(true);
                            viewBooking(ride);
                          }}
                        >
                          {" "}
                          <path
                            fill-rule="evenodd"
                            d="M6 2a.5.5 0 0 1 .47.33L10 12.036l1.53-4.208A.5.5 0 0 1 12 7.5h3.5a.5.5 0 0 1 0 1h-3.15l-1.88 5.17a.5.5 0 0 1-.94 0L6 3.964 4.47 8.171A.5.5 0 0 1 4 8.5H.5a.5.5 0 0 1 0-1h3.15l1.88-5.17A.5.5 0 0 1 6 2Z"
                            id="mainIconPathAttribute"
                          ></path>{" "}
                        </svg>
                      </div>
                    </td>
                    {/* <hr /> */}
                  </tr>
                ))
              ) : (
                <tr className="content-center flex items-center justify-center ">
                  <div className="text-center ">No Redords Available</div>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        <div class="flex flex-col items-center my-12">{/* pagination */}</div>

        <Pagination
          className="flex justify-content w-2/4"
          currentPage={currentPage}
          totalCount={requestTableData !== null ? requestTableData.length : "1"}
          pageSize={pageSize}
          containerClassName="w-12 md:flex justify-center items-center hidden  cursor-pointer leading-5 transition duration-150 ease-in  rounded-full bg-teal-600 text-white"
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
}

CardTable.defaultProps = {
  color: "light",
};

CardTable.propTypes = {
  color: PropTypes.oneOf(["light", "dark"]),
};
