import { useAuthContext } from "context/AuthProvider";
import React from "react";
import { ModalStack } from "./ModalStack";
import ViewOrderDetailsTable from "./viewOrderDetailsTable";

export default function ViewOrderDetails() {
  const {
    modalData,
    setShowModal,
    setConfirmCancelModal,
    setConfirmRequestModal,
  } = useAuthContext();

  return (
    <>
      <div
        className="justify-center  bg-black-fade flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={() => setShowModal(false)}
      >
        <div className="relative w-10/12 h-10/12 h-5/6 md:h-4/6 sm:h-4/6 my-6 mx-auto">
          {/* <div
            className="z-40 fixed top-0 left-0 w-full h-full outline-none overflow-x-hidden overflow-y-auto"
            id="exampleModalScrollable"
            tabindex="-1"
            aria-labelledby="exampleModalScrollableLabel"
            aria-hidden="true"
          > */}
          <div className="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
            <div className="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h3 className="text-3xl font-semibold uppercase">
                  Booking id: {modalData.order_request_id}
                </h3>
                <button
                  className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={() => setShowModal(false)}
                >
                  <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              </div>
              <div className="flex-auto overflow-y-auto relative p-4">
                {/* <div className="justify-center overflow-x-hidden overflow-y-auto  items-center bg-black-fade flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                  <div className="relative w-10/12  h-11/12 my-6 mx-auto"> */}
                {/*content*/}
                {/*header*/}
                {/* <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t">
                    <h3 className="text-3xl font-semibold uppercase">
                      Booking id: {modalData.order_request_id}
                    </h3>
                    <button
                      className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                      onClick={() => setShowModal(false)}
                    >
                      <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">
                        ×
                      </span>
                    </button>
                  </div> */}
                {/*body*/}
                <div className="relative p-6 flex-auto">
                  <div className="flex flex-wrap">
                    <ModalStack
                      title="Client name:"
                      content={modalData.client_name}
                    />
                    <ModalStack
                      title="item name:"
                      content={modalData.item_name}
                    />
                    <ModalStack
                      title="item type:"
                      content={modalData.item_type}
                    />
                    <ModalStack
                      title="datetime added:"
                      content={modalData.datetime_added}
                    />
                  </div>
                  <hr className="" />
                  <div className="flex flex-wrap">
                    <ModalStack
                      title="delivery confirmation code:"
                      content={modalData.delivery_confirmation_code}
                    />
                    <ModalStack
                      title="delivery date:"
                      content={modalData.delivery_date}
                    />
                    <ModalStack
                      title="delivery status:"
                      content={modalData.delivery_status}
                    />
                    <ModalStack
                      title="payment option:"
                      content={modalData.payment_option}
                    />
                  </div>
                  <hr className="" />
                  <div className="flex flex-wrap">
                    <ModalStack
                      title="number of packages:"
                      content={modalData.number_of_packages}
                    />

                    <ModalStack
                      title="Booking status:"
                      content={modalData.order_status}
                    />
                    <ModalStack
                      title="pickup facility name:"
                      content={modalData.pickup_facility_name}
                    />

                    <ModalStack
                      title="dropoff facility name:"
                      content={modalData.dropoff_facility_name}
                    />
                  </div>
                  <hr className="" />
                  <div className="flex flex-wrap">
                    {/* <ModalStack
                  title="extimated time:"
                  content={modalData.eta_time}
                /> */}
                  </div>
                  <hr className="" />

                  <div className="flex flex-wrap"></div>
                  <hr className="" />
                  <div className="flex flex-wrap">
                    <ModalStack
                      title="rider name:"
                      content={modalData.rider_name}
                    />
                    <ModalStack
                      title="rider telephone no:"
                      content={modalData.rider_telephone_no}
                    />
                  </div>

                  {/* <div className="flex flex-wrap">
           
              </div> */}
                  <hr className="" />
                  <div className="flex flex-wrap">
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          description
                        </label>
                        {modalData.description}
                      </div>
                    </div>
                    <div className="w-full lg:w-6/12 px-4">
                      <div className="relative w-full mb-3">
                        <label className="block uppercase text-blueGray-600 text-xs font-bold mb-2">
                          Item details
                        </label>
                        {modalData.prescription_note}
                      </div>
                    </div>
                  </div>
                  <hr />
                  {/* {modalData.order_items !== null ? (
                    <ViewOrderDetailsTable
                      key={modalData.order_items.map((data) => data.id)}
                      item_name={modalData.order_items.map(
                        (data) => data.item_name
                      )}
                      quantity={modalData.order_items.map(
                        (data) => data.quantity
                      )}
                    />
                  ) : null} */}
                  {modalData.order_items !== null ? (
                    <div className="block w-full overflow-x-auto">
                      <table className="items-center w-full bg-transparent border-collapse">
                        <thead>
                          <tr>
                            <th
                              className={
                                "px-6 align-middle border border-solid py-3  border-l-0 border-r-0 whitespace-nowrap  text-left  uppercase text-blueGray-600 text-xs font-bold mb-2" +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Item Name
                            </th>
                            <th
                              className={
                                "px-6 align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap  text-left  uppercase text-blueGray-600 text-xs font-bold mb-2" +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Quantity
                            </th>
                            <th
                              className={
                                "px-6 align-middle border border-solid py-3 border-l-0 border-r-0 whitespace-nowrap  text-left  uppercase text-blueGray-600 text-xs font-bold mb-2" +
                                "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                              }
                            >
                              Unit
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          {modalData.order_items.map((data, index) => (
                            <tr key={index}>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {data.item_name}
                              </td>

                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {data.quantity}
                              </td>
                              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                                {data.unit_string}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ""
                  )}
                </div>

                {/* content end*/}
              </div>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setShowModal(false)}
                >
                  Close
                </button>

                {modalData.courier_delivery_status === "pending" ? (
                  <button
                    className="bg-red-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setConfirmCancelModal(true)}
                  >
                    Cancel Order
                  </button>
                ) : modalData.courier_delivery_status === "pending" &&
                  modalData.order_status === "paid" ? (
                  <button
                    className="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setConfirmRequestModal(true)}
                  >
                    Confirm Order
                  </button>
                ) : modalData.courier_delivery_status === "assigned" ? (
                  <button
                    className="bg-emerald-600 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setConfirmRequestModal(true)}
                  >
                    Confirm Order
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>
    </>
  );
}
