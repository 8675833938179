import React from "react";

export const TimeLineStack = ({ icon, date, title, content }) => {
  return (
    <div className="mb-8 flex justify-between items-center w-full right-timeline">
      {/* <div className="order-1 w-2/4 "></div> */}
      {/* check the w-5/12 */}
      <div className="z-20 flex items-center order-1  bg-emerald-600 shadow-xl w-12 h-12 rounded-full">
        <h1 className="mx-auto font-semibold text-lg text-white">{icon}</h1>
      </div>
      <div className="order-1 bg-gray-400 rounded-lg shadow-xl w-5/6 px-6 py-4">
        <span className="font-bold mb-3 text-indigo-600 text-sm tracking-wide">
          {date}
        </span>
        {/* <h3 className="mb-3 font-bold text-gray-800 text-xl">{title}</h3> */}
        <p className="text-md mt-3 font-bold leading-snug tracking-wide text-gray-900 text-opacity-100">
          {content}
        </p>
      </div>
    </div>
  );
};
