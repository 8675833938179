import { useAuthContext } from "context/AuthProvider";
import React, { useEffect } from "react";
import { Circles } from "react-loader-spinner";

const Loader = () => {
  const { loaded, setLoaded } = useAuthContext();
  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setLoaded(false);
      }, 200000);
    }
  });

  return (
    <div className="w-screen h-screen flex justify-center items-center text-5xl font-black bg-transparent backdrop-blur-sm bg-black">
      <Circles color="#00BFFF" height={80} width={80} />
      loading...
    </div>
  );
};

export default Loader;
