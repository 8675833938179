import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";

// components

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import HeaderStats from "components/Headers/HeaderStats.js";
import FooterAdmin from "components/Footers/FooterAdmin.js";

// views

import Dashboard from "views/admin/Dashboard.js";
import Profile from "views/admin/Profile.js";
import Tables from "views/admin/Tables.js";
import CommercialForms from "views/admin/CommercialForms";
import CardMedical from "components/Cards/CardMedical";
import { useAuthContext } from "context/AuthProvider";
import Loader from "components/Loader/Loader";
import ViewOrderDetails from "components/Modals/viewOrderDetails";
import ConfirmCancel from "components/Modals/ComfirmCancel";
import ConfirmRequest from "components/Modals/ConfirmRequest";
import TimeLine from "components/Modals/TimeLine";

export default function Admin() {
  const {
    loaded,
    showModal,
    confirmRequestModal,
    confirmCancelModal,
    timeLineModal,
  } = useAuthContext();

  return (
    <>
      <Sidebar />
      {showModal && <ViewOrderDetails />}
      {timeLineModal && <TimeLine />}
      {confirmCancelModal && <ConfirmCancel />}
      {confirmRequestModal && <ConfirmRequest />}
      <div className="relative md:ml-64 bg-blueGray-100">
        <AdminNavbar />
        {/* Header */}
        <HeaderStats />
        <div className="px-4 md:px-10 mx-auto w-full -m-24">
          <Switch>
            {!loaded ? (
              <Loader />
            ) : (
              <Route path="/admin/dashboard" exact component={Dashboard} />
            )}

            {!loaded ? (
              <Loader />
            ) : (
              <Route
                path="/admin/commercialforms"
                exact
                component={CommercialForms}
              />
            )}
            {!loaded ? (
              <Loader />
            ) : (
              <Route path="/admin/cardmedical" exact component={CardMedical} />
            )}
            {!loaded ? (
              <Loader />
            ) : (
              <Route path="/admin/profile" exact component={Profile} />
            )}
            {!loaded ? (
              <Loader />
            ) : (
              <Route path="/admin/tables" exact component={Tables} />
            )}
            {!loaded ? (
              <Loader />
            ) : (
              <Redirect from="/admin" to="/admin/dashboard" />
            )}
          </Switch>
          <FooterAdmin />
        </div>
      </div>
    </>
  );
}
