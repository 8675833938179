/* eslint-disable jsx-a11y/heading-has-content */
import React, { useCallback, useRef, useState } from "react";
import Select from "react-select";
import { useAuthContext } from "context/AuthProvider";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import axios from "axios";
import { Redirect } from "react-router";
import { submitMedicalForm } from "services/service";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { Circles } from "react-loader-spinner";
import { OrderStack } from "components/FormTable/OrderStack";
// components
export default function CardMedical() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
  });
  const {
    onSearch,
    userData,
    medItemType,
    medicalInput,
    setMedicalInput,
    facility,
    REQUEST_URL,
    priority,
    medicalTable,
    medItemOptions,
    medItemDesc,
    finalmedicalTable,
    itemTypeSelected,
    setItemTypeSelected,
    medItem,
    setTableDataUpdated,
  } = useAuthContext();
  const [requestType, setRequestType] = useState("Hello type");
  const [facilitySelected, setFacilitySelected] = useState("");
  const [facilityDropoff, setFacilityDropoff] = useState("");
  const [itemName, setItemName] = useState("");
  const [description, setDescription] = useState("");
  const [qtyitem, setQtyitem] = useState("");
  const [fullname, setFullname] = useState("");
  const [priorities, setPriorities] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [autocomplete, setAutocomplete] = useState();
  const [isAutocomplete, setIsAutocomplete] = useState();
  const [coordinate, setCoordinate] = useState(null);
  const [dropOffAddress, setDropOffAddress] = useState("");
  const [secondCoordinate, setSecondCoordinate] = useState(null);
  const [homeAddress, setHomeAddress] = useState("");

  const reqType = [
    { value: "FACILITY", label: "FACILITY" },
    { value: "INDIVIDUAL", label: "INDIVIDUAL" },
  ];
  const priorityOptions = priority.map(({ name }) => ({
    value: name,
    label: name,
  }));

  const medItemTypeOptions = medItemType.map(({ item_type }) => ({
    label: item_type,
    value: item_type,
  }));
  // const medItemOptions = medItem.map(({ product_name, product_type }) => ({
  //   label: product_name,
  //   value: product_type,
  // }));

  const errRef = useRef();
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });
  const onLoad = useCallback((props) => {
    setAutocomplete(props);
  }, []);
  const onLoaded = useCallback((props) => {
    setIsAutocomplete(props);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const homeAddress = autocomplete.getPlace().formatted_address;
      var lat = autocomplete.getPlace().geometry.location.lat();
      var lng = autocomplete.getPlace().geometry.location.lng();
      setHomeAddress(homeAddress);
      setCoordinate(`${lng},${lat}`);
      setMedicalInput({
        ...medicalInput,
        pickup: homeAddress,
      });
    }
  };
  const onDropPlaceChanged = () => {
    if (isAutocomplete !== null) {
      const dropOffAddressFormated =
        isAutocomplete.getPlace().formatted_address;
      const dropOffAddressName = isAutocomplete.getPlace().name;
      const dropOffAddress =
        dropOffAddressName + " (" + dropOffAddressFormated + ")";
      var lat = isAutocomplete.getPlace().geometry.location.lat();
      var lng = isAutocomplete.getPlace().geometry.location.lng();
      setDropOffAddress(dropOffAddress);
      setSecondCoordinate(`${lng},${lat}`);
      setMedicalInput({
        ...medicalInput,
        dropoff: dropOffAddress,
      });
    }
  };

  // let something = medicalTable.map((data) => data.label + "@@");
  let insItemCode = finalmedicalTable
    .map((data) => data.ins_item_code)
    .join("@@");
  let ordQuantyList = finalmedicalTable.map((data) => data.quantity).join("@@");
  let ordUnitList = finalmedicalTable.map((data) => data.unit).join("@@");
  let orderUnitList = finalmedicalTable.map((data) => data.unit);
  let ordUnitListCheck = medicalTable.map((data) => data.units);
  // console.log("insItemCode::", insItemCode);
  // console.log("ordQuantyList::", ordQuantyList);
  // console.log("ordUnitList::", ordUnitList);
  // let newInsItemCode = () => {
  // while (insItemCode.length > 0 && insItemCode !== null) {
  //   let newnew = insItemCode.slice() + "@@";
  //   console.log("newnew:", newnew);
  // }
  // };
  // let insItemCodes = [...insItemCode, newInsItemCode];
  // console.log("insItemCode", insItemCode);
  // console.log("ordQuantyList :::", ordQuantyList);
  // console.log("ordQuantyList :::", ordUnitList);
  // console.log("something with item :::", something);
  // console.log(watch());
  // console.log(itemTypeSelected);
  // console.log("itemTypeSelected medItemOptions", medItemOptions);

  const onSubmit = async (data) => {
    // console.log(data);
    // const userID = userData.data.user_id;
    // setMedicalInput({ ...medicalInput, userID: userID });
    // submitMedicalForm(medicalInput);
    if (
      ordUnitListCheck.every((element) => element === null) ||
      (ordUnitListCheck.every((element) => element !== null) &&
        orderUnitList.every((element) => element !== undefined))
    ) {
      console.log("submit units of null");
      toast.success("sent");

      console.log("sent");
      try {
        const response = axios.post(
          REQUEST_URL,
          JSON.stringify({
            method: "REQUEST_DELIVERY",
            api_key: process.env.REACT_APP_RQUEST_API,
            user: "web",
            user_id: userData.data.user_id,
            request_type: requestType,
            item_type: itemTypeSelected,
            item_name: itemName,
            priority: data.Priority,
            pickup_facility: homeAddress,
            origin_cordinate: coordinate,
            dropoff_facility: dropOffAddress,
            destination_cordinate: secondCoordinate,
            recipient_name: data.fullname,
            recipient_email_address: data.email,
            recipient_phone_number: data.phone,
            order_item_name_list: insItemCode,
            order_item_quantity_list: ordQuantyList,
            order_item_units: ordUnitList,
          }),
          {
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
          }
        );
        // console.log(data.Priority, data.fullname, data.email, data.phone);
        if (response?.data === null || response?.data === "") {
          setErrMsg("Failed To Submit Request");
          setSuccess(false);
        } else {
          // console.log("these the response data: ", response.status);
          // console.log(medItemTypeOptions);
          // console.log("response :", response);
          setSuccess(true);
          setTableDataUpdated(true);
          toast.success("Request submitted successful");
        }
      } catch (err) {
        if (!err?.response) {
          setErrMsg("No Server Response");
        } else if (err?.response?.status === 500) {
          setErrMsg("Internal Server Error");
        } else if (err?.response?.status === 400) {
          setErrMsg("Missing Username or Password");
        } else if (err?.response?.status === 401) {
          setErrMsg("Unauthorized");
        } else if (err?.response?.status === null) {
          setErrMsg("User Not Found");
        } else {
          setErrMsg("Login Failed");
        }
        errRef.current.focus();
      }
    }
    // else if (
    //   ordUnitListCheck.every((element) => element !== null) &&
    //   orderUnitList.every((element) => element !== undefined)
    // ) {
    //   console.log("submit units of with value");
    //   toast.success("sent");

    //   console.log("sent");
    // }
    else {
      toast.error("Please specify units");
    }

    // data.reset();
    reset();
  };
  // console.log("medicalTable 2:", medicalTable);

  // console.log("finalmedicalTable", finalmedicalTable);

  return success ? (
    <Redirect to="/admin/dashboard" />
  ) : (
    <>
      {isLoaded ? (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Medical Logistics
                </h6>
              </div>
              <h6 className="text-red-700 w-full   text-xs font-bold">
                <div className="flex item-center ">
                  <svg
                    viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg"
                    id="IconChangeColor"
                    height="25"
                    width="25"
                  >
                    <path
                      fill="currentColor"
                      d="M512 64a448 448 0 1 1 0 896 448 448 0 0 1 0-896zm0 192a58.432 58.432 0 0 0-58.24 63.744l23.36 256.384a35.072 35.072 0 0 0 69.76 0l23.296-256.384A58.432 58.432 0 0 0 512 256zm0 512a51.2 51.2 0 1 0 0-102.4 51.2 51.2 0 0 0 0 102.4z"
                      id="mainIconPathAttribute"
                    ></path>
                  </svg>
                  <span className="mt-1">
                    You can only request delivery for multiple items of the same
                    type/category.
                  </span>
                </div>
              </h6>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {/*  General */}

              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>
              {/* error message */}

              <div
                className={
                  errMsg
                    ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                    : null
                }
              >
                <p
                  ref={errRef}
                  className={"text-white my-2 "}
                  aria-live="assertive"
                >
                  {errMsg}
                </p>
              </div>
              {/* error message */}

              <h2 className="heading">Request Details</h2>
              <hr className="" />
              <div className="flex flex-wrap mt-4">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="requesttype"
                    >
                      Request Type
                    </label>

                    <Controller
                      name={"requestType"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Request type is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={reqType}
                            inputRef={ref}
                            placeholder={"Select request type"}
                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={reqType.find((c) => c.value === value)}
                            onChange={(val) => {
                              onChange(val.value);
                              setRequestType(val.value);
                              // setMedicalInput({
                              //   ...medicalInput,
                              //   requestType: `${val.value.toLowerCase()}`,
                              // });
                            }}
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.requestType && (
                        <small className="text-danger text-red-500">
                          {errors.requestType.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="itemtype"
                    >
                      Item Type
                    </label>
                    <Controller
                      name={"itemType"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Item type is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={medItemTypeOptions}
                            inputRef={ref}
                            placeholder={"Select Item Type"}
                            value={medItemTypeOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.value);
                              setItemTypeSelected(val.value);
                              // setMedicalInput({
                              //   ...medicalInput,
                              //   itemType: `${val.value}`,
                              // });
                            }}
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.itemType && (
                        <small className="text-danger">
                          {errors.itemType.message}
                        </small>
                      )}{" "}
                      {/* {errors.itemtype && <p>{errors.itemtype}</p>} */}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="itemname"
                    >
                      Item Name
                    </label>
                    <Controller
                      name={"itemName"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Item Name is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            options={
                              itemTypeSelected === "WHOLE BLOOD" ||
                              itemTypeSelected === "PLATELETS" ||
                              itemTypeSelected === "PACKED CELLS" ||
                              itemTypeSelected === "FRESH FROZEN PLASMA" ||
                              itemTypeSelected === "VACCINE" ||
                              itemTypeSelected === "DRUG" ||
                              itemTypeSelected ===
                                "COLD CHAIN MEDICAL SAMPLES" ||
                              itemTypeSelected === "PHARMACEUTICAL SAMPLES" ||
                              itemTypeSelected ===
                                "HOME BASE CARE PACKAGE (COVID-19)" ||
                              itemTypeSelected === "HISTOLOGY SAMPLES" ||
                              itemTypeSelected === "MEDICAL GASES"
                                ? medItemOptions.filter((specific) => {
                                    if (specific.value !== null) {
                                      return (
                                        specific.value === itemTypeSelected
                                      );
                                    }
                                  })
                                : medItemOptions
                            }
                            inputRef={ref}
                            placeholder={"Select Item Type"}
                            value={
                              medItemOptions.find((c) => c.value === value) ||
                              null
                            }
                            onChange={(val) => {
                              onChange(val.label);
                              onSearch(val.label);

                              // setMedicalInput({
                              //   ...medicalInput,
                              //   itemName: medicalTable,
                              // });
                            }}
                          />
                        );
                      }}
                    />

                    <div className=" text-red-500">
                      {errors.itemName && (
                        <small className="text-danger">
                          {errors.itemName.message}
                        </small>
                      )}{" "}
                    </div>
                  </div>
                </div>
                {/* <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="description"
                    >
                      Description
                    </label>

                    <Controller
                      name={"description"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Item description is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={medItemDescOptions}
                            inputRef={ref}
                            placeholder={"Select description"}
                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={medItemDescOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.value);
                              setMedicalInput({
                                ...medicalInput,
                                description: `${val.value.toLowerCase()}`,
                              });
                            }}
                          />
                        );
                      }}
                    />

                    <div className=" text-red-500">
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description.message}
                        </small>
                      )}{" "}
                    </div>
                  </div>
                </div> */}
              </div>
              {/* end of first */}
              <div className=" bg-white block h-80 w-full md:w-full sm:w-full overflow-x-auto">
                <table className="items-center bg-red-500 w-full bg-transparent border-collapse">
                  <thead>
                    <tr>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Item Name
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Quantity
                      </th>
                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        unit
                      </th>
                      {/* <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Drop-Off Facility
                      </th> */}

                      <th
                        className={
                          "px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left " +
                          "bg-blueGray-50 text-blueGray-500 border-blueGray-100"
                        }
                      >
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {medicalTable.length > 0 &&
                      medicalTable.map((selection) => (
                        <OrderStack
                          key={selection.id}
                          selectedItem={selection}
                          unitMeasure="item type"
                        />
                      ))}
                  </tbody>
                </table>
              </div>
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>

              {/* start second */}
              <div className="flex flex-wrap">
                {/* <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="qtyitem"
                    >
                      Quantity (Unit/Pint)
                    </label>
                    <Controller
                      name={"qtyItem"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Item quantity is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={qty}
                            inputRef={ref}
                            placeholder={"Select Quantity"}
                            value={qty.find((c) => c.value === value)}
                            onChange={(val) => {
                              onChange(val.value);
                              setMedicalInput({
                                ...medicalInput,
                                qtyItem: `${val.value.toLowerCase()}`,
                              });
                            }}

                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        );
                      }}
                    />

                    <div className=" text-red-500">
                      {errors.qtyItem && (
                        <small className="text-danger">
                          {errors.qtyItem.message}
                        </small>
                      )}{" "}
                    </div>
                  </div>
                </div> */}
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="priority"
                    >
                      Priority
                    </label>
                    <Controller
                      name={"Priority"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Priority level is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={priorityOptions}
                            inputRef={ref}
                            placeholder={"Select priority level"}
                            value={priorityOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.value);
                              setPriorities(val.value);
                              // setMedicalInput({
                              //   ...medicalInput,
                              //   Priority: `${val.value.toLowerCase()}`,
                              // });
                            }}

                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.Priority && (
                        <small className="text-danger">
                          {errors.Priority.message}
                        </small>
                      )}{" "}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="dropoff"
                    >
                      Drop-Off Location
                    </label>
                    <Autocomplete
                      onLoad={onLoaded}
                      onPlaceChanged={onDropPlaceChanged}
                    >
                      <input
                        {...register("dropoff", {
                          required: "Please enter dropoff location.",
                        })}
                        type="text"
                        id="dropoff"
                        name="dropoff"
                        // ref={destiantionRef}
                        placeholder="Enter Dropoff Location"
                        className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={dropOffAddress}
                      />
                    </Autocomplete>
                    <div className="text-red-500">
                      {errors.dropoff && (
                        <small className="text-danger">
                          {errors.dropoff.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="pickup"
                    >
                      Pick up
                    </label>
                    <Controller
                      name={"pickup"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Pickup facility is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            options={
                              itemTypeSelected === "blood" ||
                              itemTypeSelected === "oxygen"
                                ? facilityOptions.filter((specific) => {
                                    if (specific.value !== null) {
                                      return (
                                        specific.value.toLowerCase() ===
                                        itemTypeSelected
                                      );
                                    }
                                  })
                                : facilityOptions
                            }
                            inputRef={ref}
                            placeholder={"Select Pickup Facility"}
                            value={facilityOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.label);
                              setMedicalInput({
                                ...medicalInput,
                                pickup: `${val.label.toLowerCase()}`,
                              });
                            }}
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.pickup && (
                        <small className="text-danger">
                          {errors.pickup.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="dropoff"
                    >
                      Drop-Off Facility
                    </label>
                    <Controller
                      name={"dropoff"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Dropoff facility is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={facilityDropoffOptions}
                            inputRef={ref}
                            placeholder={"Select Dropoff Facility"}
                            // className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                            value={facilityDropoffOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.value);
                              setMedicalInput({
                                ...medicalInput,
                                dropoff: `${val.value.toLowerCase()}`,
                              });
                            }}
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.dropoff && (
                        <small className="text-danger">
                          {errors.dropoff.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>
              {/* end second */}
              <div className="flex flex-wrap">
                {/* <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="pickup"
                    >
                      Pick up Location (select if known)
                    </label>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        {...register("pickup", {
                          // required: "Please enter pickup location.",
                        })}
                        type="text"
                        id="pickup"
                        name="pickup"
                        placeholder="Enter Pickup Location"
                        className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={homeAddress}
                      />
                    </Autocomplete>

                    <div className="text-red-500">
                      {errors.pickup && (
                        <small className="text-danger">
                          {errors.pickup.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div> */}
              </div>

              <h2 className="heading">Recipient Details</h2>
              <hr className="" />

              {/* start of third */}
              <div className="flex flex-wrap mt-4">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="fullname"
                    >
                      Recipient Name
                    </label>

                    <input
                      {...register("fullname", {
                        required: "Please enter your fullname.",
                      })}
                      type="text"
                      id="fullname"
                      name="fullname"
                      placeholder="Enter Full Name"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) => {
                        // setMedicalInput({
                        //   ...medicalInput,
                        //   fullname: `${e.target.value.toLowerCase()}`,
                        // });
                      }}
                    />
                    <div className=" text-red-500">
                      {errors.fullname && (
                        <small className="text-danger">
                          {errors.fullname.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Recipient Email Address
                    </label>
                    <input
                      {...register("email", {
                        required: "Please enter email address.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter Email Address"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) =>
                        setMedicalInput({
                          ...medicalInput,
                          email: `${e.target.value.toLowerCase()}`,
                        })
                      }
                    />
                    <div className="text-red-500">
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="phone"
                    >
                      Recipient Phone Number
                    </label>
                    <input
                      {...register("phone", {
                        required: "Please enter your phone number.",
                        pattern: {
                          value:
                            /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          message: "Invalid phone no",
                        },
                        maxLength: 12,
                        minLength: 10,
                      })}
                      type="text"
                      id="phone"
                      name="phone"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="Enter Phone Number"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      onChange={(e) =>
                        setMedicalInput({
                          ...medicalInput,
                          phone: `${e.target.value.toLowerCase()}`,
                        })
                      }
                    />
                    <div className="text-red-500">
                      {errors.phone && (
                        <small className="text-danger">
                          {errors.phone.message}
                        </small>
                      )}

                      {errors.phone && errors.phone.type === "maxLength" && (
                        <span>Max length exceeded</span>
                      )}
                      {errors.phone && errors.phone.type === "minLength" && (
                        <span>Number must be 10 digits long</span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold"></h6>
                <button
                  type="submit"
                  value="submit"
                  className="
                   text-sm justify-center mt-4 border border-transparent font-bold rounded w-1/4 lg:w-1/4 md:w-4/4 sm:w-4/4 
                  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                  bg-blueGray-800 disabled:bg-blueGray-600 text-white active:bg-blueGray-600 
                  uppercase px-6 py-3 shadow hover:shadow-lg outline-none mr-1 ease-linear  mb-1 transition-all 
                  duration-150"
                >
                  submit
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <Circles color="#00BFFF" height={80} width={80} />
          Loading
        </div>
      )}
    </>
  );
}
