import { useAuthContext } from "context/AuthProvider";
import React from "react";
import { ModalStack } from "./ModalStack";
import { TimeLineStack } from "./TimeLineStack";

export default function TimeLine() {
  const { modalData, setTimeLineModal } = useAuthContext();

  return (
    <>
      <div
        className="justify-center items-center bg-black-fade flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
        onClick={() => setTimeLineModal(false)}
      >
        <div className="relative  lg:w-2/5 xl:w-2/5 md:h-4/6 h-4/6 my-6 mx-auto">
          <div className="sm:h-[calc(100%-3rem)] max-w-lg my-6 mx-auto relative w-auto pointer-events-none">
            <div className="max-h-full overflow-hidden border-none shadow-lg relative flex flex-col w-full pointer-events-auto bg-white bg-clip-padding rounded-md outline-none text-current">
              <div className="flex flex-shrink-0 items-center justify-between p-4 border-b border-gray-200 rounded-t-md">
                <h5
                  className="text-xl uppercase font-medium leading-normal text-gray-800"
                  id="exampleModalScrollableLabel"
                >
                  Tracking Info
                </h5>
              </div>
              <div className="flex-auto overflow-y-auto relative p-4">
                {/* content */}
                <div className="container bg-gray-200 mx-auto w-full h-full">
                  <div className="relative wrap overflow-hidden p-10 h-full">
                    <div
                      className="ml-6 border-2-2 absolute border-opacity-20 border-gray-700 h-full border"
                      // style="left: 50%"
                    ></div>

                    {modalData.tracking_info.map((track, index) => (
                      <TimeLineStack
                        icon={index + 1}
                        date={track.datetime_added}
                        title={track.state}
                        content={track.activity}
                      />
                    ))}
                  </div>
                </div>

                {/* content end*/}
              </div>
              <div className="flex flex-shrink-0 flex-wrap items-center justify-end p-4 border-t border-gray-200 rounded-b-md">
                <button
                  className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                  type="button"
                  onClick={() => setTimeLineModal(false)}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
