import { useAuthContext } from "context/AuthProvider";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

export const OrderStack = ({ selectedItem, unitMeasure }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    control,
  } = useForm({
    mode: "all",
  });
  const {
    onRemoveFromTable,
    inc,
    dec,
    medicDetailfinal,
    itemTypeSelected,
    medItemOptions,
    medicalTable,
    setMedicalTable,
    finalmedicalTable,
    setFinalMedicalTable,
  } = useAuthContext();
  // console.log("new medicalTable::", medicalTable);
  // console.log("new setFinalMedicalTable::", finalmedicalTable);
  return (
    <tr>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        {selectedItem?.label}
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <div className="custom-number-input h-10 w-24">
          <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
            <button
              type="button"
              data-action="decrement"
              className=" bg-red-500 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none"
              onClick={() => {
                dec(selectedItem);
              }}
            >
              <span className="m-auto  text-2xl font-thin">−</span>
            </button>
            <input
              readOnly={true}
              type="text"
              className="  focus:outline-none text-center w-16 bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex item-center text-gray-700  outline-none"
              name="custom-input-number"
              //   defaultValue={selectedItem.quantity}
              value={selectedItem.quantity}
              inputMode="numeric"
              pattern="[0-9]*"
              //   onChange={handleCountChange}
            />
            {/* <input type="number" defaultValue={count} /> */}
            <button
              type="button"
              data-action="increment"
              className="bg-emerald-500 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer"
              onClick={() => {
                inc(selectedItem);
              }}
            >
              <span className="m-auto text-2xl font-thin">+</span>
            </button>
          </div>
          {/* <style
          dangerouslySetInnerHTML={{
            __html:
              "\n  input[type='number']::-webkit-inner-spin-button,\n  input[type='number']::-webkit-outer-spin-button {\n    -webkit-appearance: none;\n    margin: 0;\n  }\n\n  .custom-number-input input:focus {\n    outline: none !important;\n  }\n\n  .custom-number-input button:focus {\n    outline: none !important;\n  }\n",
          }}
        /> */}
        </div>
      </td>
      <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <div className="w-full lg:w-12/12 md:w-12/12 sm:w-12/12 px-4">
          <div className="relative w-full mb-3">
            {selectedItem?.units && selectedItem.units !== null
              ? (console.log(selectedItem.units),
                (
                  <Controller
                    name={"units"}
                    control={control}
                    defaultValue=""
                    rules={{
                      required: "Units of Measurment is required.",
                    }}
                    render={({ field: { onChange, value, name, ref } }) => {
                      return (
                        <Select
                          className="w-hscreen lg:w-6/12 md:w-6/12 xs:w-hscreen"
                          options={selectedItem?.units.map(({ name }) => ({
                            label: name,
                          }))}
                          required
                          inputRef={ref}
                          placeholder={"Select Item Type"}
                          value={selectedItem?.units.find(
                            (c) => c.label === value
                          )}
                          onChange={(val) => {
                            onChange(val.label);
                            // console.log("these is inside selction::", selectedItem);
                            medicDetailfinal(selectedItem, val.label);
                            // console.log(
                            //   "This is what goes to the api",
                            //   finalmedicalTable
                            // );
                            //console.log("Quantity value", val.label);
                            //console.log("selected row name", selectedItem.label);
                            // setFinalMedicalTable([
                            //   {
                            //     item_name: selectedItem.label,
                            //     units: val.label,
                            //   },
                            // ]);

                            // setMedicalTable(...medicalTable, { unit: val.label });
                            // setMedicalInput({
                            //   ...medicalInput,
                            //   itemName: medicalTable,
                            // });
                          }}
                        />
                      );
                    }}
                  />
                ))
              : "-"}

            <div className=" text-red-500">
              {errors.units && (
                <small className="text-danger">{errors.units.message}</small>
              )}{" "}
            </div>
          </div>
        </div>

        {/* {unitMeasure} */}
      </td>
      {/* <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4"></td> */}
      <td className="border-t-0 text-red-500 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
        <div
          className="flex cursor-pointer"
          onClick={() => {
            onRemoveFromTable(selectedItem);
          }}
        >
          REMOVE{" "}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            fill="currentColor"
            className="bi bi-trash"
            viewBox="0 0 16 16"
            id="IconChangeColor"
          >
            {" "}
            <path
              d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z"
              id="mainIconPathAttribute"
            ></path>{" "}
            <path
              fillRule="evenodd"
              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
              id="mainIconPathAttribute"
            ></path>{" "}
          </svg>
        </div>
      </td>
    </tr>
  );
};
