import React from "react";

// components

import DashboardCards from "components/Cards/DashboardCards";

export default function Dashboard() {
  return (
    <>
      <div className="flex flex-wrap">
        <DashboardCards />
      </div>
    </>
  );
}
