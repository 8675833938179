/* eslint-disable jsx-a11y/heading-has-content */
import React, { useCallback, useRef, useState } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { toast } from "react-hot-toast";
import axios from "axios";
import { useAuthContext } from "context/AuthProvider";
import { Redirect } from "react-router";
import { Circles } from "react-loader-spinner";

// components

export default function CardCommercial() {
  const { userData, commercialItem, REQUEST_URL, setTableDataUpdated } =
    useAuthContext();
  const [itemTypeSelected, setItemTypeSelected] = useState("");
  const [autocomplete, setAutocomplete] = useState();
  const [isAutocomplete, setIsAutocomplete] = useState();
  const [success, setSuccess] = useState(false);
  const [coordinate, setCoordinate] = useState(null);
  const [secondCoordinate, setSecondCoordinate] = useState(null);
  const [homeAddress, setHomeAddress] = useState("");
  const [dropOffAddress, setDropOffAddress] = useState("");
  // const [pickupLoc, setPickupLoc] = useState("");

  const [errMsg, setErrMsg] = useState("");
  const errRef = useRef();

  /** @type React.MutableRefObject<HTMLInputElement> */
  // const originRef = useRef();
  /** @type React.MutableRefObject<HTMLInputElement> */
  // const destiantionRef = useRef();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    // watch,
    control,
  } = useForm({
    mode: "all",
  });

  const medItemTypeOptions = commercialItem.map(({ item_name }) => ({
    label: item_name,
    value: item_name,
  }));
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });

  const onLoad = useCallback((props) => {
    setAutocomplete(props);
  }, []);
  const onLoaded = useCallback((props) => {
    setIsAutocomplete(props);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const homeAddressFormated = autocomplete.getPlace().formatted_address;
      const homeAddressName = autocomplete.getPlace().name;
      const homeAddress = homeAddressName + " (" + homeAddressFormated + ")";
      var lat = autocomplete.getPlace().geometry.location.lat();
      var lng = autocomplete.getPlace().geometry.location.lng();
      setHomeAddress(homeAddress);
      setCoordinate(`${lng},${lat}`);
    }
  };
  const onDropPlaceChanged = () => {
    if (isAutocomplete !== null) {
      const dropOffAddressFormated =
        isAutocomplete.getPlace().formatted_address;
      const dropOffAddressName = isAutocomplete.getPlace().name;
      const dropOffAddress =
        dropOffAddressName + " (" + dropOffAddressFormated + ")";
      var lat = isAutocomplete.getPlace().geometry.location.lat();
      var lng = isAutocomplete.getPlace().geometry.location.lng();
      setDropOffAddress(dropOffAddress);
      setSecondCoordinate(`${lng},${lat}`);
    }
  };
  // console.log("isLoaded", process.env.REACT_APP_GOOGLE_MAP_API);

  const onSubmit = (data) => {
    try {
      const response = axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "REQUEST_DELIVERY",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",
          user_id: userData.data.user_id,
          request_type: "Commercial",
          item_type: data.itemtype,
          item_name: data.itemname,
          description: data.description,
          origin_name: data.pickup,
          origin_cordinate: coordinate,
          destination_name: data.dropoff,
          destination_cordinate: secondCoordinate,
          recipient_name: data.fullname,
          recipient_email_address: data.email,
          recipient_phone_number: data.phone,
          expected_delivery_date: data.expecteddate,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      if (response?.data === null || response?.data === "") {
        setErrMsg("User Not Found");
        setSuccess(false);
      } else {
        setSuccess(true);
        setTableDataUpdated(true);
        toast.success("Request submitted successful");
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err?.response?.status === 500) {
        setErrMsg("Internal Server Error");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err?.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else if (err?.response?.status === null) {
        setErrMsg("User Not Found");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }

    reset();
  };

  return success ? (
    <Redirect to="/admin/dashboard" />
  ) : (
    <>
      {isLoaded ? (
        <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
          <form action onSubmit={handleSubmit(onSubmit)}>
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold">
                  Commercial Logistics
                </h6>
                {/* <button
                  type="submit"
                  value="submit"
                  className="inline-flex justify-center border border-transparent font-bold rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-500 text-white active:bg-lightBlue-600 uppercase text-xs px-4 py-2 shadow hover:shadow-md outline-none mr-1 ease-linear transition-all duration-150"
                >
                  Save
                </button> */}
              </div>
            </div>
            <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
              {/*  General */}
              <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase"></h6>

              <h2 className="heading">Product Details</h2>
              <hr className="" />
              <div className="flex flex-wrap mt-4">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="itemtype"
                    >
                      Item Type
                    </label>
                    <Controller
                      name={"itemtype"}
                      control={control}
                      defaultValue=""
                      rules={{
                        required: "Item type is required.",
                      }}
                      render={({ field: { onChange, value, name, ref } }) => {
                        return (
                          <Select
                            options={medItemTypeOptions}
                            inputRef={ref}
                            placeholder={"Select Item Type"}
                            value={medItemTypeOptions.find(
                              (c) => c.value === value
                            )}
                            onChange={(val) => {
                              onChange(val.value);
                              setItemTypeSelected(val.value.toLowerCase());
                            }}
                          />
                        );
                      }}
                    />

                    <div className="text-red-500">
                      {errors.itemtype && (
                        <small className="text-danger">
                          {errors.itemtype.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="itemname"
                    >
                      Item Name
                    </label>

                    <input
                      {...register("itemname", {
                        required: "Please enter Item name.",
                      })}
                      type="text"
                      id="itemname"
                      name="itemname"
                      placeholder="Enter Item Name"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      // onChange={(e) => setFullname(e.target.value.toLowerCase())}
                    />
                    <div className=" text-red-500">
                      {errors.itemname && (
                        <small className="text-danger">
                          {errors.itemname.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="description"
                    >
                      Description
                    </label>

                    <input
                      {...register("description", {
                        required: "Please enter description.",
                        minLength: 12,
                      })}
                      type="text"
                      id="description"
                      name="description"
                      placeholder="Enter Description"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <div className=" text-red-500">
                      {errors.description && (
                        <small className="text-danger">
                          {errors.description.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="quantity"
                    >
                      Quantity
                    </label>
                    <input
                      {...register("quantity", {
                        required: "Please enter quantity .",
                        maxLength: 3,
                        minLength: 1,
                      })}
                      type="text"
                      id="quantity"
                      name="quantity"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="Enter quantity "
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <div className="text-red-500">
                      {errors.quantity && (
                        <small className="text-danger">
                          {errors.quantity.message}
                        </small>
                      )}
                      {errors.quantity &&
                        errors.quantity.type === "maxLength" && (
                          <span>Max length exceeded</span>
                        )}
                      {errors.quantity &&
                        errors.quantity.type === "minLength" && (
                          <span>Number must be 1 digits long</span>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              {/* end of first */}

              {/* start second */}
              <div className="flex flex-wrap">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="pickup"
                    >
                      Pick up Location
                    </label>
                    <Autocomplete
                      onLoad={onLoad}
                      onPlaceChanged={onPlaceChanged}
                    >
                      <input
                        {...register("pickup", {
                          required: "Please enter pickup location.",
                        })}
                        type="text"
                        id="pickup"
                        name="pickup"
                        placeholder="Enter Pickup Location"
                        className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={homeAddress}
                      />
                    </Autocomplete>

                    <div className="text-red-500">
                      {errors.pickup && (
                        <small className="text-danger">
                          {errors.pickup.message}
                        </small>
                      )}
                      {/* {errors.pickup && <p>{errors.pickup}</p>} */}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    {/* <i className="fa fa-sort" /> */}
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="dropoff"
                    >
                      Drop-Off Location
                    </label>
                    <Autocomplete
                      onLoad={onLoaded}
                      onPlaceChanged={onDropPlaceChanged}
                    >
                      <input
                        {...register("dropoff", {
                          required: "Please enter dropoff location.",
                        })}
                        type="text"
                        id="dropoff"
                        name="dropoff"
                        // ref={destiantionRef}
                        placeholder="Enter Dropoff Location"
                        className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        defaultValue={dropOffAddress}
                      />
                    </Autocomplete>
                    <div className="text-red-500">
                      {errors.dropoff && (
                        <small className="text-danger">
                          {errors.dropoff.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              {/* end second */}

              <h2 className="heading">Recipient Details</h2>
              <hr className="" />

              {/* start of third */}
              <div className="flex flex-wrap mt-4">
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="fullname"
                    >
                      Recipient Name
                    </label>

                    <input
                      {...register("fullname", {
                        required: "Please enter full name .",
                      })}
                      type="text"
                      id="fullname"
                      name="fullname"
                      placeholder="Enter Full Name"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <div className=" text-red-500">
                      {errors.fullname && (
                        <small className="text-danger">
                          {errors.fullname.message}
                        </small>
                      )}{" "}
                      {/* {errors.fullname && <p>{errors.fullname}</p>} */}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="email"
                    >
                      Recipient Email Address
                    </label>
                    <input
                      {...register("email", {
                        required: "Please enter email address.",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                          message: "Invalid email address",
                        },
                      })}
                      type="text"
                      id="email"
                      name="email"
                      placeholder="Enter Email Address"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <div className="text-red-500">
                      {errors.email && (
                        <small className="text-danger">
                          {errors.email.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="phone"
                    >
                      Recipient Phone Number
                    </label>
                    <input
                      {...register("phone", {
                        required: "Please enter phone number.",
                        maxLength: 12,
                        minLength: 10,
                      })}
                      type="text"
                      id="phone"
                      name="phone"
                      inputMode="numeric"
                      pattern="[0-9]*"
                      placeholder="Enter Phone Number"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    />
                    <div className="text-red-500">
                      {errors.phone && (
                        <small className="text-danger">
                          {errors.phone.message}
                        </small>
                      )}
                      {errors.phone && errors.phone.type === "maxLength" && (
                        <span>Max length exceeded</span>
                      )}
                      {errors.phone && errors.phone.type === "minLength" && (
                        <span>Number must be 10 digits long</span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="w-full lg:w-6/12 px-4">
                  <div className="relative w-full mb-3">
                    <label
                      className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                      htmlFor="expecteddate"
                    >
                      &nbsp;&nbsp;DELIVERY DATE AND TIME
                    </label>
                    <input
                      {...register("expecteddate", {
                        required: "Please enter delivery date and time.",
                      })}
                      type="datetime-local"
                      id="expecteddate"
                      name="expecteddate"
                      placeholder="Select Date And Time"
                      className="floatLabel border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue="<?php echo date('y-m-d hh:mm'); ?>"
                    />
                    <div className="text-red-500">
                      {errors.expecteddate && (
                        <small className="text-danger">
                          {errors.expecteddate.message}
                        </small>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="rounded-t bg-white mb-0 px-6 py-6">
              <div className="text-center flex justify-between">
                <h6 className="text-blueGray-700 text-xl font-bold"></h6>
                <button
                  type="submit"
                  value="submit"
                  className="
                  text-sm justify-center mt-4 border border-transparent font-bold rounded w-1/4 lg:w-1/4 md:w-4/4 sm:w-4/4 
                  hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 
                  bg-blueGray-800 disabled:bg-blueGray-600 text-white active:bg-blueGray-600 
                  uppercase px-6 py-3 shadow hover:shadow-lg outline-none mr-1 ease-linear  mb-1 transition-all 
                  duration-150"
                >
                  submit
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div>
          <Circles color="#00BFFF" height={80} width={80} />
          Loading
        </div>
      )}
    </>
  );
}
