import CardProfile from "components/Cards/CardProfiles";
import CardProfilesDetails from "components/Cards/CardProfilesDetails.js";
import Loader from "components/Loader/Loader";
import { useAuthContext } from "context/AuthProvider";
import React from "react";

// components

// import CardSettings from "components/Cards/CardSettings.js";

export default function Profile() {
  const { loaded } = useAuthContext();

  return (
    <>
      {!loaded ? (
        <Loader />
      ) : (
        <div className="flex flex-wrap">
          <div className="w-full lg:w-8/12 px-4">
            <CardProfile />
          </div>
          <div className="w-full lg:w-4/12 px-4">
            <CardProfilesDetails />
          </div>
        </div>
      )}
    </>
  );
}
