import React, { useRef, useState } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuthContext } from "context/AuthProvider";
import { useForm } from "react-hook-form";
import { TailSpin } from "react-loader-spinner";

export default function SetPassword() {
  const { userData, setIsAuth, REQUEST_URL } = useAuthContext();

  const [isSubmitted, setIsSubmitted] = useState(false);
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    mode: "all",
  });
  const onSubmit = async (data) => {
    const response = await axios.post(
      REQUEST_URL,
      JSON.stringify({
        method: "CHANGE_PASSWORD",
        api_key: process.env.REACT_APP_RQUEST_API,
        user: "web",
        user_id: userData.data.user_id,
        old_password: data.oldpassword,
        new_password: data.password,
      }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      }
    );

    let responseReceived = response?.data.resp_code;

    if (responseReceived === "001") {
      setErrMsg(response?.data.resp_msg);
      errRef.current.focus();
    } else if (responseReceived === "000") {
      localStorage.clear();
      setIsAuth(false);
      setIsSubmitted(true);
      toast.success("Password reset successful");
    }
  };
  return (
    <>
      {isSubmitted ? (
        <>
          <Redirect to="/auth/login" />
        </>
      ) : (
        <div className="container mx-auto px-4 h-full">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    {/* error message */}

                    <div
                      className={
                        errMsg
                          ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                          : null
                      }
                    >
                      <p
                        ref={errRef}
                        className={"text-white my-2 "}
                        aria-live="assertive"
                      >
                        {errMsg}
                      </p>
                    </div>
                    {/* error message */}
                    <h6 className="text-blueGray-500 text-sm font-bold">
                      Reset Password
                    </h6>
                  </div>

                  <hr className="mt-6 border-b-1 border-blueGray-300" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="oldpassword"
                      >
                        Old Password
                      </label>
                      <input
                        {...register("oldpassword", {
                          required: "Please enter your password.",
                          // pattern: {
                          //   value:
                          //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          //   message: "Invalid password",
                          // },
                          // maxLength: 12,
                          minLength: 8,
                        })}
                        id="oldpassword"
                        name="oldpassword"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter Old Password "
                      />
                      <div className="text-red-500">
                        {errors.oldpassword && (
                          <small className="text-danger">
                            {errors.oldpassword.message}
                          </small>
                        )}

                        {errors.oldpassword &&
                          errors.oldpassword.type === "minLength" && (
                            <span>
                              Password must be atleast 8 characters long
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="password"
                      >
                        New Password
                      </label>
                      <input
                        {...register("password", {
                          required: "Please enter your password.",
                          // pattern: {
                          //   value:
                          //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          //   message: "Invalid password",
                          // },
                          // maxLength: 12,
                          minLength: 8,
                        })}
                        id="password"
                        name="password"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter New Password "
                      />
                      <div className="text-red-500">
                        {errors.password && (
                          <small className="text-danger">
                            {errors.password.message}
                          </small>
                        )}

                        {errors.password &&
                          errors.password.type === "minLength" && (
                            <span>
                              Password must be atleast 8 characters long
                            </span>
                          )}
                      </div>
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                        htmlFor="cpassword"
                      >
                        Confirm New Password
                      </label>
                      <input
                        {...register("cpassword", {
                          required: "Please enter your password.",
                          // pattern: {
                          //   value:
                          //     /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                          //   message: "Invalid password",
                          // },
                          // maxLength: 12,
                          minLength: 8,
                        })}
                        id="cpassword"
                        name="cpassword"
                        type="password"
                        className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                        placeholder="Enter New Password "
                      />
                      <div className="text-red-500">
                        {errors.cpassword && (
                          <small className="text-danger">
                            {errors.cpassword.message}
                          </small>
                        )}

                        {errors.cpassword &&
                          errors.cpassword.type === "minLength" && (
                            <span>
                              Password must be atleast 8 characters long
                            </span>
                          )}
                      </div>
                    </div>

                    <div className="text-center mt-6">
                      <button
                        disabled={isSubmitting}
                        className="bg-blueGray-800 disabled:bg-blueGray-600 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150"
                      >
                        {isSubmitting ? (
                          <div className="flex justify-evenly items-center font-black bg-transparent backdrop-blur-sm">
                            <TailSpin color="#FFFFFF" height={30} width={80} />
                            Submit
                          </div>
                        ) : (
                          "submit"
                        )}
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
