import React from "react";

// components

import CardStats from "components/Cards/CardStats.js";
import { useAuthContext } from "context/AuthProvider";

export default function HeaderStats() {
  const { requestTableData } = useAuthContext();

  const statusListPendind =
    requestTableData !== null
      ? requestTableData
          .filter((data) => data.delivery_status === "pending")
          .map((ride) => ride.delivery_status)
      : "";
  const statusListDelivered =
    requestTableData !== null
      ? requestTableData
          .filter((data) => data.delivery_status === "delivered")
          .map((ride) => ride.delivery_status)
      : "";
  // console.log(statusListDelivered);
  // const seen= new Set();
  // co
  // console.log(
  //   "statusListDelivered these for counts",
  //   statusListDelivered.length
  // );
  // console.log("requestTableData these for counts", statusListPendind.length);
  return (
    <>
      {/* Header */}
      <div className="relative bg-blueGray-800 md:pt-32 pb-32 pt-12">
        <div className="px-4 md:px-10 mx-auto w-full">
          <div>
            {/* Card stats */}
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                <CardStats
                  statSubtitle="Pending"
                  statTitle={statusListPendind.length}
                  // statArrow="up"
                  // statPercent="3.48"
                  statPercentColor="text-emerald-500"
                  // statDescripiron="Since last month"
                  statIconName="far fa-chart-bar"
                  statIconColor="bg-red-500"
                />
              </div>
              <div className="w-full lg:w-6/12 xl:w-6/12 px-4">
                <CardStats
                  statSubtitle="Delivered Bookings"
                  statTitle={statusListDelivered.length}
                  // statArrow="down"
                  // statPercent="3.48"
                  statPercentColor="text-red-500"
                  // statDescripiron="Since last week"
                  statIconName="fas fa-chart-pie"
                  statIconColor="bg-orange-500"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
