import axios from "axios";
import { useAuthContext } from "context/AuthProvider";
import React, { useState } from "react";
import { Redirect } from "react-router";
import { toast } from "react-hot-toast";
// import _ from "lodash";
export default function ConfirmCancel() {
  const {
    setShowModal,
    modalData,
    setConfirmCancelModal,
    setTableDataUpdated,
    REQUEST_URL,
    // setRequestTableData,
    // setPaginatedData,
    // pageSize,
    // userData,
  } = useAuthContext();
  const [success, setSuccess] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  // console.log(
  //   "getting order id to perform acttion:",
  //   modalData.order_request_id
  // );
  const handleSubmits = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "CANCEL_BOOKING",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",
          request_id: modalData.order_request_id,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // const tabledata = await axios.post(
      //   CONFIRM_URL,
      //   JSON.stringify({
      //     method: "GET_USER_BOOKINGS",
      //     api_key: process.env.REACT_APP_RQUEST_API,
      //     user: "web",
      //     user_id: modalData.user_id,
      //   }),
      //   {
      //     headers: { "Content-Type": "application/json" },
      //     withCredentials: true,
      //   }
      // );
      // console.log("These is for cancelation:", response);
      if (response?.data === null || response?.data === "") {
        setErrMsg("User Not Found");
        setSuccess(false);
      } else {
        setErrMsg("User Signed In");

        toast.success("Order has been cancelled");
        setTableDataUpdated(true);
        setSuccess(true);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err?.response?.status === 500) {
        setErrMsg("Internal Server Error");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err?.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else if (err?.response?.status === null) {
        setErrMsg("User Not Found");
      } else {
        setErrMsg("Request Failed");
      }
    }
  };
  return (
    <>
      {success ? (
        <Redirect to="/admin" {...setShowModal(false)} />
      ) : (
        <>
          <div className="justify-center items-center bg-black-fade flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-2/5 md:4/5 sm:4/5 h-6/12  my-6 mx-auto">
              <div className="relative px-4 w-full h-full  md:flex md:items-center md:justify-center">
                <div className="bg-white rounded-lg md:max-w-md md:mx-auto p-4 fixed inset-x-0 bottom-0 z-50 mb-4 mx-4 md:relative">
                  <div className="md:flex items-center">
                    <div className="rounded-full border bg-red-300-opacity-50   border-gray-300 flex items-center justify-center w-16 h-16 flex-shrink-0 mx-auto">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="43"
                        height="43"
                        fill="currentColor"
                        className="bi bi-exclamation-triangle-fill"
                        viewBox="0 0 16 16"
                        id="IconChangeColor"
                      >
                        {" "}
                        <path
                          d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"
                          id="mainIconPathAttribute"
                          fill="#db2929"
                        ></path>{" "}
                      </svg>
                    </div>
                    <div className="mt-4 md:mt-0 md:ml-6 text-center md:text-left">
                      <p className="font-bold">Delete your account</p>
                      <div className="inline-block">
                        <p className="text-sm text-gray-700 mt-1">
                          You will lose all of your data by canceling these
                          order.This action cannot be undone.
                        </p>
                        <p className="text-sm text-gray-700 mt-1">
                          Are you sure you want to proceed.
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="text-center md:text-right mt-4 md:flex md:justify-end">
                    <button
                      className="block  uppercase w-full md:inline-block md:w-auto px-4 py-3 md:py-2 bg-red-200 text-red-700 rounded-lg font-semibold text-sm md:ml-2 md:order-2"
                      onClick={handleSubmits}
                    >
                      Cancel Order
                    </button>

                    <button
                      className="block w-full md:inline-block md:w-auto text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                      type="button"
                      onClick={() => setConfirmCancelModal(false)}
                    >
                      Close
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className=" blur"></div>
        </>
      )}
    </>
  );
}
