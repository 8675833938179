import { useAuthContext } from "context/AuthProvider";
import React, { useCallback, useRef, useState } from "react";
import { Autocomplete, useJsApiLoader } from "@react-google-maps/api";
import { toast } from "react-hot-toast";
import { useForm } from "react-hook-form";
import axios from "axios";

// components

export default function CardProfile() {
  const { userData, setUserData, REQUEST_URL } = useAuthContext();
  const [autocomplete, setAutocomplete] = useState();
  const [coordinate, setCoordinate] = useState(null);
  const [homeAddress, setHomeAddress] = useState(null);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: "all",
  });
  const errRef = useRef();

  const [errMsg, setErrMsg] = useState("");

  const onLoad = useCallback((props) => {
    setAutocomplete(props);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const homeaddress = autocomplete.getPlace().formatted_address;
      var lat = autocomplete.getPlace().geometry.location.lat();
      var lng = autocomplete.getPlace().geometry.location.lng();
      setHomeAddress(homeaddress);
      setCoordinate(`${lng},${lat}`);
    }
  };
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAP_API,
    libraries: ["places"],
  });

  const onSubmit = (data) => {
    try {
      const response = axios.post(
        REQUEST_URL,
        JSON.stringify({
          method: "REQUEST_USER_PROFILE_UPDATE",
          api_key: process.env.REACT_APP_RQUEST_API,
          user: "web",
          user_id: userData.data.user_id,
          mobile_number: data.telephone,
          home_address: homeAddress,
          location_coordinate: coordinate,
        }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      // console.log(response);
      if (response?.data === null || response?.data === "") {
        setErrMsg("User Not Found");
        // setSuccess(false);
      } else {
        toast.success("Profile update successful");
        setUserData({
          ...userData,
          mobile_number: data.telephone,
          home_address: homeAddress,
          location_coordinate: coordinate,
        });
        // setSuccess(true);
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err?.response?.status === 500) {
        setErrMsg("Internal Server Error");
      } else if (err?.response?.status === 400) {
        setErrMsg("Missing Username or Password");
      } else if (err?.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else if (err?.response?.status === null) {
        setErrMsg("User Not Found");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return isLoaded ? (
    <>
      <div className="relative flex flex-col lg:w-7/12 min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-100 border-0">
        <form action onSubmit={handleSubmit(onSubmit)}>
          <div className="rounded-t bg-white mb-0 px-6 py-6">
            <div className="text-center flex justify-between">
              <h6 className="text-blueGray-700 text-xl font-bold">
                My account details
              </h6>
              <button
                type="submit"
                className="inline-flex justify-center border border-transparent font-bold rounded hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 bg-indigo-500 text-white active:bg-lightBlue-600 uppercase text-xs px-4 py-2 shadow hover:shadow-md outline-none mr-1 ease-linear transition-all duration-150"
              >
                Save
              </button>
            </div>
          </div>
          {/* error message */}

          <div
            className={
              errMsg
                ? "container bg-red-700 h-10 uppercase text-center flex flex-col "
                : null
            }
          >
            <p
              ref={errRef}
              className={"text-white my-2 "}
              aria-live="assertive"
            >
              {errMsg}
            </p>
          </div>
          {/* error message */}
          <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              User Information
            </h6>
            <div className="flex flex-wrap">
              {/* <div className="w-full lg:w-4/12 px-4">
                <div className="relative w-full mb-3">
                  <label className="block text-sm font-medium text-gray-700">
                    Photo
                  </label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block h-12 w-12 rounded-full overflow-hidden bg-gray-100">
                      <svg
                        className="h-full w-full text-gray-300"
                        fill="currentColor"
                        viewBox="0 0 24 24 "
                      >
                        <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                      </svg>
                    </span>
                    <button
                      type="button"
                      className="ml-5 bg-white py-2 px-3 border border-gray-300 rounded-md shadow-sm text-sm leading-4 font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                    >
                      Change
                    </button>
                  </div>
                </div>
              </div> */}
            </div>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="surname"
                  >
                    Surname
                  </label>
                  <input
                    disabled="true"
                    id="surname"
                    name="surname"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={userData.data.surname}
                    value={userData.data.surname}
                    // onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="othernames"
                  >
                    Other Names
                  </label>
                  <input
                    disabled="true"
                    id="othernames"
                    name="othernames"
                    type="text"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={userData.data.othernames}
                    value={userData.data.othernames}
                    // onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="email"
                  >
                    Email address
                  </label>
                  <input
                    disabled="true"
                    id="email"
                    name="email"
                    type="email"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    defaultValue={userData.data.email}
                    value={userData.data.email}
                    // onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="w-full lg:w-6/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="telephone"
                  >
                    Mobile No.
                  </label>
                  <input
                    {...register("telephone", {
                      required: "Please enter your phone number.",
                      pattern: {
                        value:
                          /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/,
                        message: "Invalid phone no",
                        pattern: {
                          value: "0000000000",
                          message: "Invalid phone no",
                        },
                      },
                      maxLength: 12,
                    })}
                    id="text"
                    name="telephone"
                    type="text"
                    inputMode="numeric"
                    pattern="[0-9]*"
                    className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                    placeholder="Enter Mobile Number"
                    defaultValue={userData.mobile_number}
                    // value={values.telephone}
                    // onChange={handleChange}
                  />
                  <div className="text-red-500">
                    {errors.telephone && (
                      <small className="text-danger">
                        {errors.telephone.message}
                      </small>
                    )}{" "}
                    {/* {errors.telephone && <p>{errors.telephone}</p>} */}
                  </div>
                </div>
              </div>
            </div>

            <hr className="mt-6 border-b-1 border-blueGray-300" />

            <h6 className="text-blueGray-400 text-sm mt-3 mb-6 font-bold uppercase">
              Residential Information
            </h6>
            <div className="flex flex-wrap">
              <div className="w-full lg:w-12/12 px-4">
                <div className="relative w-full mb-3">
                  <label
                    className="block uppercase text-blueGray-600 text-xs font-bold mb-2"
                    htmlFor="homeaddress"
                  >
                    Address
                  </label>
                  <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                    <input
                      {...register("homeaddress", {
                        required: "Please enter home address.",
                      })}
                      id="homeaddress"
                      name="homeaddress"
                      type="text"
                      placeholder="Enter Home Address"
                      className="border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150"
                      defaultValue={homeAddress}
                    />
                  </Autocomplete>
                  <div className="text-red-500">
                    {errors.homeaddress && (
                      <small className="text-danger">
                        {errors.homeaddress.message}
                      </small>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </>
  ) : (
    <div>Loading</div>
  );
}
